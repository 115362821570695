import { React } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Listing from "components/globals/Listing";
import PSPage from "components/globals/ui/layout/Page"
import { useFetchData } from "helpers/utils";
import { Button } from 'components/globals/ui/input/Button';

const UserCarRecords = () => {
  const { id } = useParams();

  // double entry, entry.entry & entry.user
  const { data: entry, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/garage/car/${id}`,
		[id],
	);

  const handleTotal = (total) => {
    console.log('total', total);
  };

    // Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

  return (
    <PSPage>
      <h1>Public records for car: {entry.entry.title}</h1>
        
      <Link to={`/car/${entry.entry.internal_id}`}>
        <Button variant='outline'>
          BACK TO CAR DETAIL
        </Button> 
      </Link>

      {/* params: /car_id */}
      <Listing
        apiPath="garage/records"
        apiParams={entry.entry.internal_id}
        type="card"
        onTotalChange={handleTotal}
      />
    </PSPage>
  );
};

export default UserCarRecords;

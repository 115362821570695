import { React } from "react";
import { useFetchData } from "helpers/utils";

const GroupListingCount = ({ groupId }) => {

  const { data: entry, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/groups/listingCount/${groupId}`,
		[groupId],
	);

	if (loading) return <span>Loading...</span>;
	if (error) return <span>Error: {error.message}</span>;

  return (
		<>
			<span>{ entry.count }</span>
		</>
  );
};

export default GroupListingCount;

import React from "react";
import Listing from "components/globals/Listing"
import PSPage from "components/globals/ui/layout/Page"

export default function UserCars() {
  return (
    <>
      <PSPage>
        <h1>Cars</h1>

        {/* params: /user_id/omit/make */}
        <Listing
          apiPath="garage"
          apiParams={`all/none/all/false`}
          type="grid"
        />

      </PSPage>
    </>
  );
};

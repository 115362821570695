
import { React } from "react";


export default function PSCardPrice({price}) {

	return (
		<>
			<span className="absolute z-[4] bottom-2 right-2 block font-bold bg-[#398E33]/80 text-white px-2 py-1 rounded-small">${ price }</span>
		</>
  );
}
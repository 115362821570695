import React from "react";
import PSButton from "components/globals/ui/Button";

export default function PSPageSegmentHeading({heading, all}) {
  return (
    <>
      <div className="flex items-center mb-3 p-[15px] md:p-0">
        { heading ? (
          <h3 className="style-h3 grow">{heading}</h3>
        ): null }

        { all ? (
          <PSButton to={all} />
        ) : null }
      </div>
      
    </>
  );
};

import React from "react";

export function PSFormColumns({children}) {
  return (
    <>
      <div className="flex align-start flex-wrap md:flex-nowrap w-11/12 md:2-full mx-auto mt-5 mb-lg">
        {children}
      </div>
    </>
  );
};


export function PSFormColumn({children, heading}) {
  return (
    <>
      <div className="w-full mb-5 md:w-1/2 md:mr-5 mr-0">

				{ heading ? (
					<h2>{heading}</h2>
				) : null }
				
        {children}
      </div>
    </>
  );
};


export function PSFormControls({children}) {
  return (
    <>
      <div className="
				fixed
				bottom-0
				left-0
				w-full
				px-[40px]
				py-3
				flex
				justify-end
				bg-black/90">
        {children}
      </div>
    </>
  );
};

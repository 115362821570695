/* usage

<PSSelect
  control={control}
  name="carColor"
  label="Car Color"
  placeholder="Select a color"
>
  <PSSelectItem value="red">Red</PSSelectItem>
  <PSSelectItem value="blue">Blue</PSSelectItem>
  <PSSelectItem value="green">Green</PSSelectItem>
</PSSelect>

*/


import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Label } from "./Label";


// Define SelectItem component first
export const PSSelectItem = React.forwardRef(({ children, ...props }, forwardedRef) => {
  return (
    <Select.Item 
			className='SelectItem
				leading-none
				rounded-md
				flex
				items-center
				h-7
				px-5
        text-black
				relative
				select-none' {...props} ref={forwardedRef}>
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className="SelectItemIndicator">
        <CheckIcon />
      </Select.ItemIndicator>
    </Select.Item>
  );
});

export const PSSelect = ({
  control,
  name,
  elementId,
  label,
  placeholder,
  children
}) => {
  return (
    <div id={elementId}>
      <Label htmlFor={name}>{label}</Label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value = "" } }) => (
          <Select.Root value={value} onValueChange={onChange}>
            <Select.Trigger
              className="
                SelectTrigger 
                border-[1px]
                text-black
                border-black 
                focus:border-purple 
                inline-flex 
                items-center 
                justify-center 
                rounded-lg 
                px-4 
                gap-1 
                leading-none 
                h-9 
                bg-white
                shadow-md 
                hover:bg-mauve-3 
                focus:shadow-outline 
                focus:outline-none"
              aria-label={label}
            >
              <Select.Value placeholder={placeholder} />
              <Select.Icon className="SelectIcon">
                <ChevronDownIcon />
              </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
              <Select.Content className="SelectContent overflow-hidden bg-white rounded-xl shadow-xl">
                <Select.ScrollUpButton className="SelectScrollButton flex items-center justify-center h-9 bg-white cursor-default">
                  <ChevronUpIcon />
                </Select.ScrollUpButton>
                <Select.Viewport className="SelectViewport p-1">
                  <Select.Group>{children}</Select.Group>
                </Select.Viewport>
                <Select.ScrollDownButton className="SelectScrollButton">
                  <ChevronDownIcon />
                </Select.ScrollDownButton>
              </Select.Content>
            </Select.Portal>
          </Select.Root>
        )}
      />
    </div>
  );
};

	
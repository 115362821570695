import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Single from "components/globals/image/Single";
import parse from "html-react-parser";
import Listing from "components/globals/Listing";
import PSPage from "components/globals/ui/layout/Page"
import PSPageSegment from "components/globals/ui/layout/PageSegment"
import { useFetchData, formatDate } from "helpers/utils";

const UserProfile = () => {
  const [formattedDate, setFormattedDate] = useState(0);
  const { username } = useParams();

	const { data: user, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/users/publicUser/${username}`,
		[username],
	);

	useEffect(() => {
    setFormattedDate(formatDate(user?.created_at));
  }, [user]);

	const handleTotal = (total) => {
    // console.log('total', total);
  };

	// Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;
	
  return (
    <PSPage>

			<div className="relative">
									
				{/* BANNER */}
				<div className="relative aspect-[16/6] w-full overflow-hidden">
					{user.banner_image ? (
						<Single image={user.banner_image}/>
					) : (
						<Single />
					)}

					<div className="expand z-[2] bg-gradient-to-b from-black/0 to-black/50"></div>
				</div>

				{/* PROFILE */}
				<div className="relative aspect-square w-[200px] -mt-[100px] rounded-default overflow-hidden z-[3] ml-[20px]">
					{user.profile ? (
						<Single image={user.profile} />
					) : (
						<Single />
					)}
				</div>

				<h1 className="relative -top-[80px] ml-[250px]">{ user.username }</h1>

			</div>


			<h2>Member Number: { user.memberNumber }</h2>
			<p>User since: { formattedDate }</p>
			<div>{parse(user.bio)}</div>

			<div>
				<h2>Details</h2>

					<div>
					
					<PSPageSegment heading="Garage">

						{/* params: /user_id/omit/make/shuffle */}
						<Listing
							apiPath="garage"
							apiParams={`${user.user_id}/none/all/false`}
							type="card"
							onTotalChange={handleTotal}
							mobileCarousel
						/>
					</PSPageSegment>
					
					<PSPageSegment 
						heading="Recent Listings" 
						all={`/listings/user/${username}`}>

						{/* params: /user_id/omit/make/shuffle */}
						<Listing
							apiPath="listings"
							apiParams={`${user.user_id}/none/all/false`}
							limit={3} 
							type="row"
							onTotalChange={handleTotal} />

					</PSPageSegment>

					<PSPageSegment 
						heading="Recent Want-Ads" 
						all={`/wants/user/${username}`}>

						{/* params: /user_id/omit/make/shuffle */}
						<Listing
							apiPath="wants"
							apiParams={`${user.user_id}/none/all/false`}
							type="row"
							limit={3}
							onTotalChange={handleTotal}
						/>

					</PSPageSegment>

					<PSPageSegment 
						heading="Recent Updates (timeline)" 
						all={`/users/${username}/timeline`}>

							{/* params: /id */}
							<Listing
								apiPath="users/timeline"
								apiParams={user.user_id}
								type="card"
								limit={6}
								onTotalChange={handleTotal}
								mobileCarousel
							/>

					</PSPageSegment>

				</div>


			</div>
			
    </PSPage>
  );
};

export default UserProfile;

import React, { useState, useEffect } from 'react';
import { 
	PSCollapsableContainer,
	PSCollapsableContainerHeading,
	PSCollapsableContainerContent
} from "components/globals/ui/PSCollapsableContainer"

import { useGetUserEntriesQuery } from 'services/auth/authService';
import { FaPlus } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { Button } from 'components/globals/ui/input/Button';

// cards and rows
import CarRow from "components/rows/CarRow"
import RecordRow from "components/rows/RecordRow"
import ListingRow from "components/rows/ListingRow"
import WantRow from "components/rows/WantRow"
import TimelineRow from "components/rows/TimelineRow"
import EventRow from "components/rows/EventRow"
import GroupRow from "components/rows/GroupRow"
import UserRow from "components/rows/UserRow"
import ProjectRow from "components/rows/ProjectRow"

export default function PSUserListing({id, heading, url, newLink, allLink}) {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const limit = 3;
  const { data, error, isLoading } = useGetUserEntriesQuery({page, limit, url});

  const rowComponetns = {
		record: RecordRow,
		listing: ListingRow,
		want: WantRow,
		timeline: TimelineRow,
		garage: CarRow,
    event: EventRow,
    group: GroupRow,
    user: UserRow,
    project: ProjectRow,
	};

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  useEffect(() => {
    if (data?.entries) {
      setTotal(data?.total);
      setItems((prevItems) => {
        const newItems = data.entries.filter(
          (newItem) => !prevItems.some((item) => item.internal_id === newItem.internal_id)
        );
        return [...prevItems, ...newItems];
      });
    }
  }, [data]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>There was an error loading the garage data.</p>;
  }

  const loadMoreItems = () => {
    setPage((prevPage) => prevPage + 1);
  }

  return (
    <>
      <div className="border-b-[1px] border-b-[#303030] px-xs last:border-b-0" id={id}>
      <PSCollapsableContainer startShown={false}>
        <PSCollapsableContainerHeading>
          <div className="flex items-center">
            <h3>{ heading }</h3>
            <span className="font-bold text-[13px] py-1 px-3 bg-white/10 rounded-full ml-3">{total}</span>
          </div>
        </PSCollapsableContainerHeading>

        <PSCollapsableContainerContent>
            <Link to={newLink} className="mr-2">
              <Button variant='outline'>
                <FaPlus />
                <span className="block text-[13px] ml-2 uppercase font-bold">Add New</span>  
              </Button>
            </Link>
            <Link to="{allLink}">
              <Button variant='secondary'>
                <span className="block text-[13px] uppercase font-bold">View All</span>  
              </Button>
            </Link>
            {items.map((entry, index) => {
              const Component = rowComponetns[entry.entry_type];
              return Component ? (
                <Component key={entry._id} item={entry} isFirst={index === 0} noUserBadge noYoursBadge />
              ) : (
                <div key={entry._id}>Unknown Type - {entry.entry_type}</div>
              );
            })}
          
            {data?.entries?.length === limit && (
              <Button variant='outline' className="my-sm" onClick={loadMoreItems}>
                Load More
              </Button>          
            )}
          </PSCollapsableContainerContent>
        </PSCollapsableContainer>
      </div>
    </>
  );
}
import React from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';

const PSCheckbox = ({ className, control, name, label }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value, ref } }) => (
    
      <div className={classNames("flex items-center cursor-pointer", className)}>
        <Checkbox.Root
          className="
            bg-black 
            hover:bg-black 
            flex 
            h-[25px] 
            w-[25px] 
            appearance-none 
            items-center 
            justify-center 
            rounded-small
            border-[1px]
            border-input 
            outline-none"
          id={name}
          checked={value}
          onCheckedChange={onChange}
          ref={ref}
        >
          <Checkbox.Indicator className="text-white">
            <CheckIcon />
          </Checkbox.Indicator>
        </Checkbox.Root>
        <label
          className="
            pl-[15px] 
            text-[15px] 
            leading-none 
            text-white 
            relative 
            block
            cursor-pointer"
          htmlFor={name}
        >
          {label}
        </label>
      </div>
    )}
  />
);

export default PSCheckbox;

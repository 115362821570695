import React from "react";
import Editor from 'react-simple-wysiwyg';
import { Controller } from "react-hook-form";
import { Label } from "./Label";

export default function CreateCar({control, name, label, initial, elementId}) {

  return (
		<>
			<div className="my-5">
        {label && (
          <Label htmlFor={elementId}>{label}</Label>
        )}
        <Controller
          name={name}
          control={control}
          defaultValue={initial || ''}
          render={({ field: { onChange, value } }) => (
            <Editor
              id={name}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          )}
        />
			</div>
		</>
  );
}
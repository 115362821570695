import React from "react";

export default function PSRowInfo({children}) {
  return (
    <>
      <div className="grow mx-3">
				{children}
      </div>
    </>
  );
};

import React from 'react';
import { NavLink } from 'react-router-dom';

import { PSSidebar } from "components/globals/ui/PSSidebar"
import { PSDivider } from "components/globals/ui/PSDivider"
import { PSNewLinks } from "components/globals/PSNewLinks"

import { 
	PSCollapsableContainer,
	PSCollapsableContainerHeading,
	PSCollapsableContainerContent
} from "components/globals/ui/PSCollapsableContainer"

export const DashboardSidebar = function({userInfo}) {
  return (

    <div className="flex flex-wrap flex-col">
    
      <PSSidebar>

        <PSCollapsableContainer startShown={true}>

          <PSCollapsableContainerHeading>
            <h4 className="uppercase"><strong>New</strong></h4>
          </PSCollapsableContainerHeading>

          <PSCollapsableContainerContent>
            <PSNewLinks />
          </PSCollapsableContainerContent>
    
        </PSCollapsableContainer>

        <PSDivider theme='dark' />	

        <PSCollapsableContainer startShown={true}>

          <PSCollapsableContainerHeading>
            <h4 className="uppercase"><strong>Links</strong></h4>
          </PSCollapsableContainerHeading>

          <PSCollapsableContainerContent>
            <ul>
              <li>
                <NavLink to={`/users/${userInfo.username}`}>Public Profile</NavLink>
              </li>
            </ul>
          </PSCollapsableContainerContent>

        </PSCollapsableContainer>

        <PSDivider theme='dark' />	

        <PSCollapsableContainer startShown={true}>

          <PSCollapsableContainerHeading>
            <h4 className="uppercase"><strong>Dashboard</strong></h4>
          </PSCollapsableContainerHeading>

          <PSCollapsableContainerContent>
            <ul>
              <li>
                <NavLink to='/dashboard'>Dashboard</NavLink>
              </li>
              <li>
                <NavLink to='/settings'>Preferences</NavLink>
              </li>
              <li>
                <NavLink to='/settings'>Logout</NavLink>
              </li>
            </ul>
          </PSCollapsableContainerContent>

        </PSCollapsableContainer>

      </PSSidebar>

    
    </div>
    

  );
}
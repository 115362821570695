import React from "react";
import PSPageSegmentHeading from "./SegmentHeading";

export default function PSPageSegment({children, heading, all}) {
  return (
    <>
      <div className="
        md:p-[30px] 
        my-[30px] 
        md:bg-segment 
        md:rounded-default
        md:border-b-0 
        border-b-[1px] 
        border-[#202020]
        pb-[30px]">
				<PSPageSegmentHeading heading={heading} all={all} />
        {children}
      </div>
    </>
  );
};

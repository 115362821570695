import { React, useState, useEffect } from "react"
import {PortableText} from '@portabletext/react'
import sanityClient from "sanity-client"
import PSPage from "components/globals/ui/layout/Page"

const Roadmap = () => {
  const [content, setContent] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    sanityClient.fetch(
      `*[_type == 'page' && slug == 'roadmap']{
        title,
        slug,
        body,
      }`
    )
    .then((data) => setContent(data[0]))
    setIsLoading(false)
  }, [])

  return (
    <>
      <PSPage>
        <h1 className="text-3xl font-bold underline mb-[30px]">Partstash: Roadmap</h1>

        {isLoading ? (
          <h2> Loading... </h2>
        ) : (
          <div>
            <PortableText
              value={content.body}
            />
          </div>
        )}

      </PSPage>
    </>
    
  );
};

export default Roadmap;

import * as RadioGroup from '@radix-ui/react-radio-group';
import React from 'react';
import { Label } from "./Label";
import { Controller } from 'react-hook-form';

export const PSRadioItem = React.forwardRef(({ id, value, label }, ref) => {
  return (
    <div className="flex items-center">
      <RadioGroup.Item
        ref={ref} // Correctly forwarding the ref to the RadioGroup.Item component
        className="bg-white w-[25px] h-[25px] rounded-full hover:bg-white focus:shadow-[0_0_0_2px] focus:shadow-black outline-none cursor-default"
        value={value}
        id={id}
      >
        <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[15px] after:h-[15px] after:rounded-[50%] after:bg-black" />
      </RadioGroup.Item>
      <label className="text-white text-[15px] leading-none pl-[15px]" htmlFor={id}>
        {label}
      </label>
    </div>
  );
});

export const PSRadioGroup = ({
	control,
	name,
	elementId,
  defaultValue,
	label,
	children,
}) => {
  return (

    <div id={elementId} className="my-5">
			<Label htmlFor={elementId}>{label}</Label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup.Root
            className="flex flex-col gap-2.5"
            {...field}
            aria-label={label}
            onValueChange={field.onChange}
          >
            {children}
          </RadioGroup.Root>
        )}
      />
    </div>
  )
}

	
import { React } from "react";
import PSGenericRow from "components/globals/ui/Row";

export default function RecordRow({
	item, 
	noYoursBadge = false,
	noTypeBadge, 
	noUserBadge,
	noUserName,
}) {

	return (
		<>
			<PSGenericRow
				item={item}
				path="records"
				carInfo
				profile={item.profile}
				noYoursBadge={noYoursBadge}
				noTypeBadge={noTypeBadge}
				noUserBadge={noUserBadge}
				noUserName={noUserName}
			/>
		</>
  );
}
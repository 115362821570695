import React from "react";
import Listing from "components/globals/Listing"
import PSPage from "components/globals/ui/layout/Page"

export default function EventsPage() {
  return (
    <>
      <PSPage>
        <h1>Events</h1>
        
        {/* params: none yet */}
        <Listing
          apiPath="events"
          apiParams=""
          type="card" />

      </PSPage>
    </>
  );
};

import { React , useState} from "react";
import PSButton from "components/globals/ui/Button";
import PSInputText from "components/globals/ui/input/PSInputText"

export default function CreateProjectItems({ onItemsChange }) {
	const [items, setItems] = useState([]); 

	const handleAddItem = () => {
    setItems([...items, ""]);
  };

	// const handleNoteChange = (index, value) => {
  //   const updatedItems = [...items];
  //   updatedItems[index] = value;
  //   setItems(updatedItems);
  //   if (onItemsChange) {
  //     onItemsChange(updatedItems);
  //   }
  // };

	return (
		<div className="mb-3">
			<h3>Project Items</h3>

			{items.map((note, index) => (
        <PSInputText name='itemInfo' label='Item Info' type='text' />
      ))}
      
			<PSButton onClick={handleAddItem} label="Add Item" />

		</div>
  );
}
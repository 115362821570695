
import { React } from "react";


export default function PSCardPrice({price}) {

	return (
		<>
			<span className="absolute z-[4] bottom-1 right-1 block font-bold bg-[#398E33]/80 text-white px-1 py-1 text-[10px] rounded-small">${ price }</span>
		</>
  );
}
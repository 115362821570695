import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black/90 text-white p-[15px] md:p-[30px]">
      <p>2024 Partstash. Built by <a href='mailto:matt@partstash.com'>Matthew Aebersold</a>.</p>
    </footer>
  );
};

export default Footer;

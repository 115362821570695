import React from "react";
import { formatTitle } from "helpers/utils";

export default function PSRowTitle({title}) {

	return (
		<>
			<span className="block font-bold text-[16px]">{ formatTitle(title) }</span>
		</>
  );
}
import React from "react";

function InputGroup({children}) {
  return (
    <>
      <div className="my-sm">
        {children}
      </div>
    </>
  );
};


export { InputGroup }
import React, { useState, useEffect, useCallback } from "react";
import PSPage from "components/globals/ui/layout/Page";
import Error from 'components/globals/Error';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import PSInputText from "components/globals/ui/input/PSInputText";
import PSSubmit from "components/globals/ui/input/PSSubmit";
import {PSSelect, PSSelectItem} from "components/globals/ui/input/PSSelect";
import { createEntry, updateEntry } from 'store/authSlice';
import { prepareFormData, scrollToTop, fetchPostAssociation } from "helpers/utils";
import { projectTypes } from "helpers/types"
import { PSFormColumns, PSFormColumn, PSFormControls } from "components/globals/ui/forms/PSForms"
import SuccessModal from "components/modals/Success"
import PSWysiwyg from "components/globals/ui/input/PSWysiwyg";
import { useSelector } from 'react-redux'
// import { DevTool } from '@hookform/devtools';
import { useGetUserEntryQuery } from 'services/auth/authService';
import { Button } from 'components/globals/ui/input/Button';
import { Link } from 'react-router-dom';
import { PSRadioGroup, PSRadioItem } from "components/globals/ui/input/PSRadio";
import CreateProjectNotes from "components/globals/projects/CreateProjectNotes";
import CreateProjectItems from "components/globals/projects/CreateProjectItems";
import SingleImageUploader from "components/globals/ui/image/SingleImageUploader"

export default function CreateCar({edit = false}) {
	const { id } = useParams();
	const dispatch = useDispatch();
	const form = useForm();
	const { handleSubmit, reset, register, control, watch } = form;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	const [successItem, setSuccessItem] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [cars, setCars] = useState([]);
	const userToken = useSelector((state) => state.auth.userToken);
	// const [notes, setNotes] = useState([]);
	// const [items, setItems] = useState([]);

	// validation
	const title = watch('title');
	const body = watch('body');
	const isFormValid = title && body;

	// what if this is new?
	const { data: entryData, isLoading: entryLoading, error: entryError } = 
		useGetUserEntryQuery(edit ? `projects/${id}` : null, { skip: !edit });

	const loadCars = useCallback(async () => {
		try {
			const carEntries = await fetchPostAssociation(userToken, 'garage');
			setCars(carEntries);
		} catch (err) {
			console.error("Error fetching cars:", err);
			setError(err.message);
		}
	}, [userToken]);

	useEffect(() => {
		loadCars();
	}, [loadCars]);

	useEffect(() => {
		const initialize = async () => {
			if (edit && entryData && entryData.entry.car_id) {
					reset({
							...entryData.entry,
							car_id: entryData.entry.car_id,
					});
			}
		};

		initialize();
	}, [edit, entryData, reset]);
	

  const handleNotesChange = (updatedNotes) => {
    // setNotes(updatedNotes);
  };


  const handleItemsChange = (updatedItems) => {
    // setItems(updatedItems);
  };

	const submitForm = async (data) => {
		await processEntry(data, edit);
	};
	

	const processEntry = async (data, edit = false) => {
		if (!userToken || (edit && !id)) return;
	
		// Set initial state for loading, messages, etc.
		setLoading(true);
		setError(null);
		setSuccess(false);
		setSuccessMessage('');
	
		const formData = prepareFormData(data);
	
		try {
			const action = edit ? updateEntry({data: formData, type: 'project'}) : createEntry({data: formData, type: 'project'});
			const result = await dispatch(action).unwrap();
	
			const successMessage = edit
				? `Successfully updated project: ${data.title}`
				: `Successfully created new project: ${data.title}`;
	
			setSuccessItem(result);
			setSuccess(true);
			setSuccessMessage(successMessage);
			reset();
		} catch (err) {
			setError(err.message || 'Failed. Please try again.');
		} finally {
			setLoading(false);
			scrollToTop();
		}
	};
	

	const closeModal = () => {
		setSuccess(false);
	}

	// TODO: page components for these?
	if (entryLoading) {
		return <PSPage>Loading project details...</PSPage>;
	}
	if (entryError) {
		return <PSPage><Error message="Failed to load project." /></PSPage>;
	}

	return (
		<PSPage>
		
			<div className="w-11/12 mx-auto">
				<h1>{edit ? 'Edit' : 'New'} Project</h1>
			</div>

			{error && <Error message={error} />}
			{success && 
				<SuccessModal
					visible={success}
					label={successMessage}
					item={successItem}
					onClose={closeModal}
					actions={[
						{
							to: `/projects/${successItem?._id}`,
							label: "View",
						},
						{
							to: `/projects/${successItem?._id}/edit`,
							label: "Edit",
							onClick: closeModal,
						},
						{
							to: `/new/project`,
							label: "New Project",
							onClick: closeModal,
						},
						{
							to: `/dashboard`,
							label: "Dashboard",
						},
					]}
				/>
			}

			<FormProvider {...form}>
				<form onSubmit={handleSubmit(submitForm)}>
					{/* <DevTool control={control} /> */}
					<PSFormColumns>
						<PSFormColumn heading="Required Fields">
							<PSInputText
								register={register} 
								name='title' 
								label='Project Name' 
								type='text' 
								required
							/>

							{/* Main Image */}
              <SingleImageUploader register={register} reset={reset} />
							
							<PSWysiwyg elementId='body' label='Description' control={control} name='body' />

							<PSRadioGroup name="type" elementId='typeProject' defaultValue='genearal' label='Project Type'>
								{projectTypes.map((type) => (
									<PSRadioItem
										key={type.key} 
										id={type.key} 
										value={type.key} 
										label={type.label} 
									/>
								))}
              </PSRadioGroup>
					 
							<PSSelect
								name='car_id'
								elementId='select-example'
								placeholder='Select Car' 
								label='Car to associate with this project'>
									<PSSelectItem value="none">No car</PSSelectItem>
									{ cars.length > 0 && (
										<> 
											{cars.map((car) => (
												<PSSelectItem value={car.internal_id} key={car.internal_id}>
													{car.title} ({car.year} {car.make} {car.model})
												</PSSelectItem>
											))}
										</>
									)}
							</PSSelect>
							
						</PSFormColumn>

						<PSFormColumn heading="Optional Fields">
							<p>Pre-fill project info...</p>

							{/* TODO: sync this with the form data */}
							<CreateProjectNotes onNotesChange={handleNotesChange} />
							<CreateProjectItems onItemsChange={handleItemsChange}/>
              
            </PSFormColumn>

					</PSFormColumns>

					<PSFormControls>

						<div className="flex items-center">
							{edit && (
								<Link to={`/projects/${entryData?.entry?.internal_id}`} className="mr-2">
									<Button variant='outline'>
										<span className="block text-[13px] uppercase font-bold">Cancel</span>  
									</Button>
								</Link>
							)}
							<PSSubmit
								loading={loading}
								label={loading ? (edit ? 'Updating...' : 'Creating...') : (edit ? 'Update Project' : 'Create Project')}
								disabled={loading || !isFormValid}
							/>
						</div>

					</PSFormControls>
				</form>
			</FormProvider>
		</PSPage>
	);
}
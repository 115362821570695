import { React } from "react";
import { useAuth } from 'providers/AuthContext';
import PSBadge from "components/globals/ui/PSBadge"

export default function IsYoursBadge({comparison}) {
	const { userInfo, isFetching } = useAuth();

	return (
		<>
			{ !isFetching && comparison ===  userInfo?.user_id ? (
				<PSBadge color='green' label='yours' />
			): null }				
		</>
  );
}
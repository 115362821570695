import React from 'react';
import { Input } from "components/globals/ui/input/Input";
import { Label } from "components/globals/ui/input/Label";
import { InputGroup } from "components/globals/ui/input/Group";

export default function PSInputText({ register, name, label, type, required }) {
  return (
    <InputGroup>
      <Label htmlFor={name}>
        <span>{label}</span>
        {required && (
          <span className="font-bold text-red">*</span>
        )}
      </Label>
      <Input
        required={required}
        type={type}
        {...register(name)}
      />
    </InputGroup>
  );
}
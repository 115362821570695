import React from "react";

export default function PSWidgetContainer({ children }) {
	return (
		<>
			<div className="
				relative
				bg-blue/30
				rounded-default 
				py-1 
				px-3">				
				{children}
			</div>
		</>
    
  );
}
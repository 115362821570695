import * as React from "react"
import { NavLink } from 'react-router-dom';

import {
	DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/globals/ui/dropdown/PSDropdown";

export default function MainNavigation() {
  
  return (

		<DropdownMenu>
			<DropdownMenuTrigger asChild className="cursor-pointer">
				<p>MENU</p>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56 mr-3 mt-2 border-black border-[#303030] bg-[#202020]">
				<DropdownMenuLabel>Marketplace</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					<NavLink to='/all'>
						<DropdownMenuItem className="cursor-pointer">
							All Listings
						</DropdownMenuItem>
					</NavLink>

					<NavLink to='/listings'>
						<DropdownMenuItem className="cursor-pointer">
							Part listings
						</DropdownMenuItem>
					</NavLink>
					
					<NavLink to='/wants'>
						<DropdownMenuItem className="cursor-pointer">
							Want-ads
						</DropdownMenuItem>
					</NavLink>
				</DropdownMenuGroup>

				<DropdownMenuSeparator />
				<DropdownMenuLabel>Social</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					<NavLink to='/timeline'>
						<DropdownMenuItem className="cursor-pointer">
							Timeline
						</DropdownMenuItem>
					</NavLink>

					<NavLink to='/users'>
						<DropdownMenuItem className="cursor-pointer">
							Users
						</DropdownMenuItem>
					</NavLink>
					
					<NavLink to='/cars'>
						<DropdownMenuItem className="cursor-pointer">
							Cars
						</DropdownMenuItem>
					</NavLink>

					<NavLink to='/groups'>
						<DropdownMenuItem className="cursor-pointer">
							Groups
						</DropdownMenuItem>
					</NavLink>
					
					<NavLink to='/events'>
						<DropdownMenuItem className="cursor-pointer">
							Events
						</DropdownMenuItem>
					</NavLink>

				</DropdownMenuGroup>

				<DropdownMenuSeparator />
				<DropdownMenuLabel>Partstash</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					<NavLink to='/about'>
						<DropdownMenuItem className="cursor-pointer">
							About
						</DropdownMenuItem>
					</NavLink>

					<NavLink to='/features'>
						<DropdownMenuItem className="cursor-pointer">
							Features
						</DropdownMenuItem>
					</NavLink>
					
					<NavLink to='/changelog'>
						<DropdownMenuItem className="cursor-pointer">
							Changelog
						</DropdownMenuItem>
					</NavLink>

					<NavLink to='/roadmap'>
						<DropdownMenuItem className="cursor-pointer">
							Roadmap
						</DropdownMenuItem>
					</NavLink>

				</DropdownMenuGroup>


			</DropdownMenuContent>
		</DropdownMenu>

  );
};

import { React, useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Single from "components/globals/image/Single";
import { useAuth } from 'providers/AuthContext';
import {
	DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "components/globals/ui/dropdown/PSDropdown";
import { PSNewLinks } from "components/globals/PSNewLinks"


export const PSUserDropdownNavigation = function({userInfo}) {
	const { handleLogout } = useAuth();
  const [profileImage, setProfileImage] = useState(null);
  const navigate = useNavigate();

  const onLogout = () => {
    handleLogout();
    navigate('/');
  };

  useEffect(() => {
    
    if(userInfo) {
      setTimeout(function() {
        setProfileImage(userInfo.profile);
      }, 500);
    }
  }, [userInfo]); 
  
  return (
    <>
			<div className="relative">
				<DropdownMenu>
					<DropdownMenuTrigger asChild className="cursor-pointer">
						<span className="block relative w-[30px] h-[30px] rounded-small overflow-hidden border-[1px] border-grey">
							<Single key={profileImage} image={profileImage} />
						</span>
					</DropdownMenuTrigger>
					<DropdownMenuContent className="w-56 mr-3 mt-2 border-black border-[#303030] bg-[#202020]">
						<DropdownMenuLabel>My Account</DropdownMenuLabel>
						<DropdownMenuSeparator />
						<DropdownMenuGroup>
							<NavLink to='/dashboard'>
								<DropdownMenuItem className="cursor-pointer">
									Dashboard
								</DropdownMenuItem>
							</NavLink>

							<DropdownMenuGroup>
								<DropdownMenuSub>
									<DropdownMenuSubTrigger>NEW</DropdownMenuSubTrigger>
									<DropdownMenuPortal>
										<DropdownMenuSubContent className="border-black border-[#303030] bg-[#202020]">
											<PSNewLinks />
										</DropdownMenuSubContent>
									</DropdownMenuPortal>
								</DropdownMenuSub>
							</DropdownMenuGroup>


							<NavLink to={`/users/${userInfo.username}`}>
								<DropdownMenuItem className="cursor-pointer">
									Public Profile
								</DropdownMenuItem>
							</NavLink>
							
							<NavLink to='/settings'>
								<DropdownMenuItem className="cursor-pointer">
									Settings
								</DropdownMenuItem>
							</NavLink>
						</DropdownMenuGroup>
						<DropdownMenuSeparator />
						<DropdownMenuGroup>
							<DropdownMenuItem className="cursor-pointer">Activity</DropdownMenuItem>
							<DropdownMenuSub>
								<DropdownMenuSubTrigger>My Content</DropdownMenuSubTrigger>
								<DropdownMenuPortal>
									<DropdownMenuSubContent className="border-black border-[#303030] bg-[#202020]">
										<DropdownMenuItem className="cursor-pointer">Listings</DropdownMenuItem>
										<DropdownMenuItem className="cursor-pointer">Want-Ads</DropdownMenuItem>
									</DropdownMenuSubContent>
								</DropdownMenuPortal>
							</DropdownMenuSub>
						</DropdownMenuGroup>
						<DropdownMenuSeparator />
						<DropdownMenuItem className="cursor-pointer" onClick={onLogout}>
							Log out
							<DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
				
			</div>

		</>
  );
};

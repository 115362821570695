import { React } from "react";
import { useParams } from "react-router-dom";
import Listing from "components/globals/Listing"
import PSPage from "components/globals/ui/layout/Page"
import { useFetchData } from "helpers/utils";

export default function ListingsByUser() {
	const { username } = useParams();
	const { data: user, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/users/publicUser/${username}`,
		[username],
	);

	// Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	return (
		<>
			<PSPage>
				<div>
					<h1>Wants for user: { user.username }</h1>
						{/* params: /user_id/omit/make/shuffle */}
						<Listing
							apiPath="wants"
							apiParams={`${user.user_id}/none/all/false`}
							type="card"
						/>

				</div>
			</PSPage>
		</>
	);
};
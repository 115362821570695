import { React } from "react";
import { useAuth } from 'providers/AuthContext';
import { Button } from 'components/globals/ui/input/Button';
import DeleteEntryUI from 'components/globals/utils/DeleteEntryUI';
import { Link } from 'react-router-dom';
import { getEntryTypePath } from "helpers/types";

const IsYoursBanner = ({ label, entry }) => {
  const { userInfo, isFetching } = useAuth();

  return (
		<>
			{ !isFetching && entry.user_id ===  userInfo?.user_id && (
				<div className="flex items-center border-2 border-green py-2 pl-5 pr-1 text-white rounded-default mb-sm">
					<p className="grow uppercase tracking-[.5px] text-[13px] font-bold">
						{ label ? (
							<>{label}</>
						) : (
							<>Your Entry</>
						)}
					</p>

					<div className="flex items-center">
						<Link to={`/${getEntryTypePath(entry.entry_type)}/${entry.internal_id}/edit`} className="mr-2">
							<Button variant='outline'>
								<span className="block text-[13px] uppercase font-bold">Edit</span>  
							</Button>
						</Link>
						<DeleteEntryUI entry={entry} />
					</div>
				</div>
			)}
			
		</>
  );
};

export default IsYoursBanner;

import React from "react";

export default function PSWidgetScroller({ children }) {
	return (
		<>
			<div className="relative max-h-[500px] overflow-auto">				
				{children}
			</div>
		</>
    
  );
}
import { React } from "react";
import { useParams } from "react-router-dom";
import Single from "components/globals/image/Single";
import Listing from "components/globals/Listing";
import PSPage from "components/globals/ui/layout/Page"
import PSPageSegment from "components/globals/ui/layout/PageSegment"
import { useFetchData } from "helpers/utils";

const UserProfile = () => {
  const { username } = useParams();
	const { data: user, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/users/publicUser/${username}`,
		[username],
	);
	
	// Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

  return (
    <PSPage>

				{/* PROFILE */}
				<div className="relative aspect-square w-[200px] rounded-default overflow-hidden z-[3] ml-[20px]">
					{user.profile ? (
						<Single image={user.profile} />
					) : (
						<Single />
					)}
				</div>

				<h1 className="relative -top-[80px] ml-[250px]">{ user.username }</h1>
				
				<PSPageSegment heading="Garage">

					{/* params: /user_id/omit/make/shuffle */}
					<Listing
						apiPath="garage"
						apiParams={`${user.user_id}/none/all/false`}
						type="card"
					/>
				</PSPageSegment>
    </PSPage>
  );
};

export default UserProfile;

import React from "react";
import { FaEllipsisH } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import { useAuth } from 'providers/AuthContext';
import { getEntryTypePath } from "helpers/types";
import DeleteEntryUI from 'components/globals/utils/DeleteEntryUI';

import {
	DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "components/globals/ui/dropdown/PSDropdown";

export default function EntryUserActions({comparison, item}) {
  const { userInfo, isFetching } = useAuth();

  return (
    <>
      { !isFetching && comparison ===  userInfo?.user_id ? (
        <div className="relative">
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="cursor-pointer">
            <span className="relative mt-3 p-2 border-primary block overflow-hidden border-2 border-[#333333] rounded-small">
              <FaEllipsisH className="relative top-[1px]" />
            </span>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 mr-3 mt-2 border-black border-[#303030] bg-[#202020]">
            <DropdownMenuLabel>Your Actions</DropdownMenuLabel>
            <DropdownMenuGroup>
              <NavLink to={`/edit/${getEntryTypePath(item?.type)}/${item.internal_id}`}>
                <DropdownMenuItem className="cursor-pointer text-white">
                  Edit
                </DropdownMenuItem>
              </NavLink>
              <DeleteEntryUI entry={item} />
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      ) : null }
    </>
  );
};
import * as React from "react"
import { NavLink } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import { useAuth } from 'providers/AuthContext';
import { Button } from 'components/globals/ui/input/Button';
import UserHeader from 'components/globals/ui/UserHeader'
import MainNavigation from 'components/globals/nav/MainNavigation'

const Header = () => {
  const { userInfo, isFetching } = useAuth();
  
  return (
    <header className="hidden md:block">

      <div className="fixed w-full z-[50] h-[50px] bg-black/90 w-full text-white flex justify-start items-center px-5">
        <NavLink className="nav-item" to="/">
          <img src={logo} alt='Partstash' className="block h-[30px] w-auto" />
        </NavLink>

        <NavLink className="ml-3" to="/posts">
          <p className="text-[14px] font-bold uppercase">Posts</p>
        </NavLink>

        <div className="relative grow ml-1">
          <MainNavigation />
        </div>

        <div className="flex items-center shrink-0">
          { !isFetching && (
            <>
              {userInfo ? null : (
                <NavLink className="button" to="/login">
                  <Button variant='outline'>Login</Button>
                </NavLink>
              )}

              <span className="ml-3">
                {userInfo ? (
                  <UserHeader />
                ) : null }
              </span>

            </>
          )}
        </div>
        
      </div>


    </header>
  );
};

export default Header;

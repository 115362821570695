import React from "react";

export default function BottomLeftSlot2({children}) {
  return (
    <>
      <div className="absolute bottom-[47px] left-[5px]">
				{children}
      </div>
    </>
  );
};

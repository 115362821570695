import {React, useEffect, useRef} from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { Button } from "components/globals/ui/input/Button";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const SuccessModal = ({ visible, label, item, onClose, actions }) => {
  const modalRef = useRef(null);

	useEffect(() => {
    const modalElement = modalRef.current;
    if (visible && modalElement) {
      disableBodyScroll(modalElement);
    } else {
      enableBodyScroll(modalElement);
    }

    // Clean up when the component unmounts or visibility changes
    return () => {
      enableBodyScroll(modalElement);
    };
  }, [visible]);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-[#191919] rounded-lg p-6 shadow-lg w-11/12 max-w-md"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
          >
            <h2 className="text-xl font-bold mb-4">{label}</h2>
            <div className="flex flex-col space-y-2">
              {actions.map((action, index) => (
                <Link to={action.to} key={index} className="w-full">
                  <Button
                    onClick={action.onClick || onClose}
                    variant={action.variant || "outline"}
                  >
                    <span className="block text-[13px] uppercase font-bold">
                      {action.label}
                    </span>
                  </Button>
                </Link>
              ))}
            </div>
            <button
              onClick={onClose}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SuccessModal;

import React, { useState, useEffect, useCallback } from "react";
import PSPage from "components/globals/ui/layout/Page";
import Error from 'components/globals/Error';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import PSInputText from "components/globals/ui/input/PSInputText";
import PSSubmit from "components/globals/ui/input/PSSubmit";
import {PSSelect, PSSelectItem} from "components/globals/ui/input/PSSelect";
import PSCheckbox from "components/globals/ui/input/PSCheckbox";
import { createEntry, updateEntry } from 'store/authSlice';
import { prepareFormData, scrollToTop, fetchPostAssociation } from "helpers/utils";
import { PSFormColumns, PSFormColumn, PSFormControls } from "components/globals/ui/forms/PSForms"
import SuccessModal from "components/modals/Success"
import PSWysiwyg from "components/globals/ui/input/PSWysiwyg";
import { useSelector } from 'react-redux'
import { postTypes, postCategories } from "helpers/types"
import { useGetUserEntryQuery } from 'services/auth/authService';
import { Button } from 'components/globals/ui/input/Button';
import { Link } from 'react-router-dom';
import SingleImageUploader from "components/globals/ui/image/SingleImageUploader"
import { formatTitle } from "helpers/utils";

export default function CreatePost({edit = false}) {
	const { id } = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	const form = useForm();
	const { handleSubmit, reset, register, control, watch } = form;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	const [successItem, setSuccessItem] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');

	// post type and category state
	const [postType, setPostType] = useState('');
	const [postCategory, setPostCategory] = useState('');
	
	const userToken = useSelector((state) => state.auth.userToken);

	const [cars, setCars] = useState([]);
	const [events, setEvents] = useState([]);
	const [projects, setProjects] = useState([]);
	const [groups, setGroups] = useState([]);
	const [clearImage, setClearImage] = useState(false);
	const [existingProfile, setExistingProfile] = useState(null);
	const [loaded, setLoaded] = useState(false);

	// validation of required fields
	const title = watch('title');
	const isFormValid = title;

	// what if this is new?
	const { data: entryData, isLoading: entryLoading, error: entryError } = 
		useGetUserEntryQuery(edit ? `post/${id}` : null, { skip: !edit });

	// load associations (cars, groups, projects, event)
	const loadAssociations = useCallback(async () => {
		try {
			const carEntries = await fetchPostAssociation(userToken, 'garage');
			const projectEntries = await fetchPostAssociation(userToken, 'projects');
			const eventEntries = await fetchPostAssociation(userToken, 'events');
			const groupEntries = await fetchPostAssociation(userToken, 'groups');

			setCars(carEntries);
			setEvents(eventEntries);
			setGroups(groupEntries);
			setProjects(projectEntries);

		} catch (err) {
			console.error("Error fetching post:", err);
			setError(err.message);
		}
	}, [userToken]);

	useEffect(() => {
		loadAssociations();
	}, [loadAssociations]);

	useEffect(() => {

		const initialize = async () => {
			await loadAssociations();
				if (entryData) {
					const entry = entryData.entry;
					if (edit) {
						setPostType(entry.type);
						setPostCategory(entry.category);

						// if profile, set it
						if(entry.profile) {
							setExistingProfile(entry.profile);
						}

						reset({
							...entry,
							car_id: entry.car_id || '',
							group_id: entry.group_id || '',
							project_id: entry.project_id || '',
							event_id: entry.event_id || '',
						});
					}
				}
				setTimeout(() => {
					setLoaded(true);
				}, 1000);
			};

		if(entryData) {
			initialize();
		} else {
			setTimeout(function() {
				setLoaded(true);
			}, 1000);
		}
	}, [entryData]);

	const submitForm = async (data) => {
		await processEntry(data, edit);
	};
	
	const processEntry = async (data, edit = false) => {

		if (!userToken || (edit && !id)) return;
	
		// Set initial state for loading, messages, etc.
		setLoading(true);
		setError(null);
		setSuccess(false);
		setSuccessMessage('');
	
		const formData = prepareFormData(data);
		formData.append('type', postType);
		formData.append('category', postCategory);
	
		try {
			const action = edit ? updateEntry({data: formData, type: 'post', clearImage: clearImage}) : createEntry({data: formData, type: 'post'});
			const result = await dispatch(action).unwrap();
	
			const successMessage = edit
				? `Successfully updated Post: ${data.title}`
				: `Successfully created new Post: ${data.title}`;
	
			setSuccessItem(result);
			setSuccess(true);
			setSuccessMessage(successMessage);
			reset();
		} catch (err) {
			setError(err.message || 'Failed. Please try again.');
		} finally {
			setLoading(false);
			scrollToTop();
		}
	};
	
	const closeModal = () => {
		setSuccess(false);
	}
	
	// Set initial type and category
	useEffect(() => {
		if(!edit && postTypes.length > 0) {
			setPostType(postTypes[0].key);
		}
	}, []);

	// Watch for route changes to clear form and scroll to top
	// TODO: Fix this
  // useEffect(() => {
  //   reset();
  //   scrollToTop();
  // }, [location.pathname]);

	// Update category when postType changes
	useEffect(() => {
		const filteredCategories = postCategories
			.filter((category) => category.type === postType)
			.flatMap((category) => category.items);

		if(loaded) {
			if(filteredCategories.length > 0) {
				setPostCategory(filteredCategories[0].key);
			} else {
				setPostCategory('');
			}	
		}
	}, [postType]);

	const handleImageChange = (newImage) => {
		if(!newImage) {
			setClearImage(true);
		} else {
			setClearImage(false);
		}
  };

	// TODO: page components for these?
	if (entryLoading) {
		return <PSPage>Loading Post details...</PSPage>;
	}
	if (entryError) {
		return <PSPage><Error message="Failed to load post." /></PSPage>;
	}

	return (
		<PSPage>

			{loaded && (
				<>
				<div className="w-11/12 mx-auto">
				<h1>{edit ? 'Edit' : 'New'} Post - {postType}</h1>
			</div>

			{/* type */}
			<div className="bg-[#202020] p-3 rounded-[10px] my-3 w-11/12 mx-auto">
				<h3 className="uppercase text-[14px] font-bold text-white mb-3">Post Type</h3>
				{postTypes.map((type) => (
					<Button 
						className="mr-2 mb-2" 
						key={type.key} 
						variant="secondary"
						onClick={() => setPostType(type.key)}
						active={postType === type.key}
					>
						{type.label}
					</Button>
				))}
			</div>

			{/* category */}
			<div className="bg-[#202020] p-3 rounded-[10px] my-3 w-11/12 mx-auto">
				<h3 className="uppercase text-[14px] font-bold text-white mb-3">Post Category</h3>
				{postCategories
					.filter((category) => category.type === postType)
					.flatMap((category) => category.items)
					.map((item) => (
						<Button 
							active={postCategory === item.key}
							className="mr-2 mb-2"
							key={item.key} 
							variant="secondary"
							onClick={() => setPostCategory(item.key)}
						>
							{item.label}
						</Button>
					))}
			</div>

			{error && <Error message={error} />}
			{success && 
				<SuccessModal
					visible={success}
					label={successMessage}
					item={successItem}
					onClose={closeModal}
					actions={[
						{
							to: `/post/${successItem?._id}`,
							label: "View",
						},
						{
							to: `/edit/post/${successItem?._id}`,
							label: "Edit",
							onClick: closeModal,
						},
						{
							to: `/new/post`,
							label: "New Record",
							onClick: closeModal,
						},
						{
							to: `/dashboard`,
							label: "Dashboard",
						},
					]}
				/>
			}

			<FormProvider {...form}>
				<form onSubmit={handleSubmit(submitForm)}>

					<PSFormColumns>
						<PSFormColumn>
							<PSInputText
								register={register} 
								name='title' 
								label='Title' 
								type='text' 
								required
							/>

							{/* Main Image */}
              <SingleImageUploader 
								existingImage={existingProfile ? `//ps-images.imgix.net/${existingProfile}?q=70&width=200` : null} 
								register={register} 
								reset={reset}
								onImageChange={handleImageChange} />

							<PSWysiwyg elementId='body' control={control} name='body' />
					 
							<PSSelect
								name='car_id'
								elementId='select-example'
								placeholder='Select Car' 
								label='Car to associate with this record'>
									<PSSelectItem value="none">No car</PSSelectItem>
									{ cars.length > 0 && (
										<> 
											{cars.map((car) => (
												<PSSelectItem value={car.internal_id} key={car.internal_id}>
													{formatTitle(car.title)} ({car.year} {car.make} {car.model})
												</PSSelectItem>
											))}
										</>
									)}
							</PSSelect>

							<PSSelect
								name='group_id'
								elementId='select-group'
								placeholder='Select Group' 
								label='Group to associate with this record'>
									<PSSelectItem value="none">No group</PSSelectItem>
									{ groups.length > 0 && (
										<> 
											{groups.map((group) => (
												<PSSelectItem value={group.internal_id} key={group.internal_id}>
													{formatTitle(group.title)}
												</PSSelectItem>
											))}
										</>
									)}
							</PSSelect>

							<PSSelect
								name='event_id'
								elementId='select-event'
								placeholder='Select Event' 
								label='Event to associate with this record'>
									<PSSelectItem value="none">No event</PSSelectItem>
									{ events.length > 0 && (
										<> 
											{events.map((event) => (
												<PSSelectItem value={event.internal_id} key={event.internal_id}>
													{formatTitle(event.title)}
												</PSSelectItem>
											))}
										</>
									)}
							</PSSelect>

							<PSSelect
								name='project_id'
								elementId='select-project'
								placeholder='Select Project' 
								label='Project to associate with this record'>
									<PSSelectItem value="none">No project</PSSelectItem>
									{ projects.length > 0 && (
										<> 
											{projects.map((project) => (
												<PSSelectItem value={project.internal_id} key={project.internal_id}>
													{project.title}
												</PSSelectItem>
											))}
										</>
									)}
							</PSSelect>
							
						</PSFormColumn>

						<PSFormColumn>
			        <PSCheckbox name='private' label='Mark as Private' control={control} />

							{postType === 'listing' && (
								<>
									<PSInputText register={register} name='price' label='Price' type='text' />
									<PSInputText register={register} name='vin' label='VIN' type='text' />
									<PSInputText register={register} name='condition' label='Condition' type='text' />
									<PSInputText register={register} name='mileage' label='Mileage' type='text' />
								</>
							)}

            </PSFormColumn>

					</PSFormColumns>

					<PSFormControls>

						<div className="flex items-center">
							{edit && (
								<Link to={`/post/${entryData?.entry?.internal_id}`} className="mr-2">
									<Button variant='outline'>
										<span className="block text-[13px] uppercase font-bold">Cancel</span>  
									</Button>
								</Link>
							)}
							<PSSubmit
								loading={loading}
								label={loading ? (edit ? 'Updating...' : 'Creating...') : (edit ? 'Update Post' : 'Create Post')}
								disabled={loading || !isFormValid}
							/>
						</div>

					</PSFormControls>
				</form>
			</FormProvider>
				</>
			)}
		
			
		</PSPage>
	);
}
import * as React from "react"
import { FaPlus } from "react-icons/fa6";
import { useAuth } from 'providers/AuthContext';
import { NavLink } from "react-router-dom";
import { PSUserDropdownNavigation } from 'components/globals/user/navigation/dropdown/Header'

const UserHeader = () => {
  const { userInfo } = useAuth();
  
  return (
		<div className="flex items-center">
			<PSUserDropdownNavigation userInfo={userInfo} />
			<NavLink className="nav-item" to="/new/post">
				<div className="
					cursor-pointer 
					w-[30px] 
					h-[30px] 
					bg-[#202020] 
					rounded-full 
					ml-3 
					flex 
					items-center 
					justify-center"
				>
					<FaPlus />
				</div>
			</NavLink>
		</div>
  );
};

export default UserHeader;

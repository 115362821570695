import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Gallery from "components/globals/image/Gallery";
import User from "components/globals/user/badge";
import parse from "html-react-parser";
import PSPage from "components/globals/ui/layout/Page"
import Listing from "components/globals/Listing";
import PSPageSegment from "components/globals/ui/layout/PageSegment"
import { formatDate, useFetchData } from "helpers/utils";

const Event = () => {
  const [formattedDate, setFormattedDate] = useState(0);
  const { id } = useParams();

	const { data: entry, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/events/event/${id}`,
		[id],
	);

  useEffect(() => {
    setFormattedDate(formatDate(entry?.entry?.event_date));
  }, [entry]);

	const handleTotal = (total) => {
    // console.log('total', total);
  };

	// Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;
	

  return (
		<>
			<PSPage>

				<div>
					{entry.entry.gallery.length > 0 ? (
						<Gallery gallery={entry.entry.gallery} bucket='groups' /> 
					) : (
						<div>FALLBACK IMG</div>
					)}
				</div>

				<h1>Group: { parse(entry.entry.title) }</h1>
				<p className="mb-2">Event Date: <strong>{formattedDate}</strong></p>

				<div>
					<div>
						<div className="flex items-center mb-2">
							<p className="mr-1 bold">Hosted By:</p>
							<User userId={entry.entry.user_id} />
						</div>

						<p className="mb-2">TYPE: <strong>{ entry.entry.type }</strong></p>
						<p className="mb-2">WHERE: <strong>{ entry.entry.location }</strong></p>
						<p className="mb-2">LINK: <strong><a href={ entry.entry.location_url } target="blank">EVENT MEETUP LINK</a></strong></p>
						<p className="mb-2">FREQUENCY: <strong>{ entry.entry.recurring_frequency }</strong></p>

						{entry.body ? (
							<div>{parse(entry.entry.body)}</div>
						) : null }

						{/* EVENT ATTENDEES */}
						<PSPageSegment heading="Attendees">

							{/* params: /group_id */}
							<Listing
								apiPath="events/attendees"
								apiParams={entry.entry.internal_id}
								type="card"
								onTotalChange={handleTotal}
								mobileCarousel
							/>
							
						</PSPageSegment>

						{/* ATTENDEES CARS */}
						<PSPageSegment heading="Attendee Cars">

							{/* params: /group_id */}
							<Listing
								apiPath="events/attendee-cars"
								apiParams={entry.entry.internal_id}
								type="card"
								onTotalChange={handleTotal}
								mobileCarousel
							/>
							
						</PSPageSegment>

					</div>
				</div>
			</PSPage>
		
		</>
  );
};

export default Event;

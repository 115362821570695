import { React } from "react";
import { Link } from "react-router-dom";
import Single from "components/globals/image/Single";
import PSRow from "components/globals/ui/Row";
import PSRowImageContainer from "components/globals/ui/row/ImageContainer"
import PSRowInfo from "components/globals/ui/row/Info"
import PSRowTitle from "components/globals/ui/row/Title"
import PSRowDate from "components/globals/ui/row/Date"

export default function UserRow({item, isFirst}) {

	return (
		<>
			<div key={item._id}>
				<PSRow isFirst={isFirst}>

					<Link className="flex items-start w-full overflow-hidden" to={`/users/${item.username}`}>
						<PSRowImageContainer>
							{item.profile ? (
								<Single image={item.profile} bucket='profiles' />
							) : (
								<Single />
							)}
						</PSRowImageContainer>

						<PSRowInfo>
							<PSRowTitle title={item.username} />
							<PSRowDate label="Member Since" date={item.created_at} />
						</PSRowInfo>
					</Link>

				</PSRow>
			</div>
		</>
  );
}
import { React } from "react";
import PSGenericRow from "components/globals/ui/Row";

export default function CarRow({
	item, 
	noTypeBadge, 
	noUserBadge,
	noUserName,
	noYoursBadge = false
}) {

	return (
		<>
			<PSGenericRow
				item={item}
				path="car"
				carInfo
				noTypeBadge={noTypeBadge}
				noUserBadge={noUserBadge}
				noUserName={noUserName}
				noYoursBadge={noYoursBadge}
			/>
		</>
  );
}
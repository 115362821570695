import React from "react";

export default function PSRowImageContainer({children}) {
  return (
    <>
      <div className="relative border-[1px] border-[#0A0A0A]/50 rounded-small md:rounded-small overflow-hidden w-[100px] aspect-square md:aspect-[4/3] shrink-0">
				{children}
      </div>
    </>
  );
};

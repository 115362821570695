// ProtectedRoute.js
import { useSelector } from 'react-redux'
import { NavLink, Outlet } from 'react-router-dom'
import { Button } from "components/globals/ui/input/Button"

const ProtectedRoute = () => {
  const { userInfo } = useSelector((state) => state.auth)

  // show unauthorized screen if no user is found in redux store
  if (!userInfo) {
    return (
      <div className='text-center mt-lg'>
        <h1>Unauthorized</h1>
        <div className="flex items-center justify-center mt-sm">
					<NavLink to='/login' className="mr-sm">
            <Button>Login</Button>
          </NavLink>

					<NavLink to='/register'>
            <Button variant='outline'>Register</Button>
          </NavLink>

        </div>
      </div>
    )
  }

  // returns child route elements
  return <Outlet />
}
export default ProtectedRoute
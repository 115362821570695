import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from 'store/authSlice';
import PSPage from 'components/globals/ui/layout/Page';
import { Button } from 'components/globals/ui/input/Button';
import Error from 'components/globals/Error';
import PSInputText from "components/globals/ui/input/PSInputText"
import PSSubmit from "components/globals/ui/input/PSSubmit"

export default function Login() {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const form = useForm();
  const { handleSubmit } = form;
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      navigate('/dashboard');
    }
  }, [navigate, userInfo]);

  const submitForm = (data) => {
    dispatch(userLogin(data));
  };

  return (
    <>
      <PSPage>
        <div className="max-w-[600px] w-11/12 mx-auto mt-lg">
          <h1>Login</h1>

          <form onSubmit={handleSubmit(submitForm)}>
            {error && <Error>{error}</Error>}

            <PSInputText register={form.register} name='email' label='Email Address' type='text' />
						<PSInputText register={form.register} name='password' label='Password' type='password' />

            <PSSubmit label='Login' loading={loading} />
            
            <NavLink to="/register">
              <Button variant="link">Sign up for a new account</Button>
            </NavLink>
          </form>
        </div>
      </PSPage>
    </>
  );
}

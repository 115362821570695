import React from "react";
import Listing from "components/globals/Listing"
import PSPage from "components/globals/ui/layout/Page"

export default function GroupsPage() {
  return (
    <>
      <PSPage>
        <h1>Groups</h1>

        {/* params: none yet */}
        <Listing
          apiPath="groups"
          apiParams=""
          type="grid"
        />

      </PSPage>
    </>
  );
};

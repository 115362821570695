import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Single from "components/globals/image/Single";
import User from "components/globals/user/badge";
import Listing from "components/globals/Listing"
import parse from "html-react-parser";
import PSPage from "components/globals/ui/layout/Page"
import PSPageSegment from "components/globals/ui/layout/PageSegment"
import CarDetails from "components/globals/car/Details"
import { useFetchData, formatDate } from "helpers/utils";
import IsYoursBanner from 'components/globals/IsYoursBanner'

const UserCar = () => {
  const [formattedDate, setFormattedDate] = useState(0);
  const { id } = useParams();

  // double entry, entry.entry & entry.user
  const { data: entry, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/garage/car/${id}`,
		[id],
	);

  // useEffect(() => {
  //   if (entry) {
  //     console.log("Fetched entry data:", entry);
  //   }
  // }, [entry]);

  useEffect(() => {
    setFormattedDate(formatDate(entry?.entry?.created_at));
  }, [entry]);

  const handleTotal = (total) => {
    console.log('total', total);
  };

  // Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;


  return (
    <>
      <PSPage>

        <IsYoursBanner label="Your Car" entry={entry.entry} />
      
        {/* header */}
        <div className="flex flex-wrap md:flex-nowrap items-stretch w-[95%] md:w-full mx-auto">

          {/* image */}
          <Single
            className="relative w-full md:w-[50%] shrink-0 rounded-default overflow-hidden" 
            natural 
            image={entry.entry.profile ? entry.entry.profile : null} 
            bucket='cars' 
          />

          {/* Car Information */}
          <div className="grow mt-xs md:mt-0 md:ml-sm px-xs md:px-sm py-xs bg-segment rounded-default">

            {/* title */}
            <h1 className="m-0">{entry.entry.title}</h1>

            <CarDetails 
              year={entry.entry.year} 
              make={entry.entry.make} 
              model={entry.entry.model} 
              trim={entry.entry.trim}
            />

            {/* owner */}
            <User 
              noPadding 
              userId={entry.entry.user_id} 
            />

            {/* Created */}
            <div className="uppercase text-[12px] tracking-[.5px]">Added: <strong>{formattedDate}</strong></div>

            {/* Body */}
            <div className="mt-3">{parse(entry.entry.body)}</div>

          </div>

        </div>


      <div>
        

        <div>

          
          {/* <ul className="flex">
            {entry.generation_from ? (
              <li>From: {entry.generation_from}</li>
            ) : null}
            {entry.generation_to ? (
              <li>To: {entry.generation_to}</li>
            ) : null}
            {entry.mileage ? <li>mileage: {entry.mileage}</li> : null}
            {entry.horsepower ? <li>horsepower: {entry.horsepower}</li> : null}
            {entry.torque ? <li>torque: {entry.torque}</li> : null}

            {entry.stock ? <li>STOCK</li> : <li>MODIFIED</li>}
          </ul> */}
        </div>


        <PSPageSegment
          heading={`Other cars in the garage for: ${ entry.user.username }`}
          all={`/users/${entry.user.username}/garage`}>
          
          {/* params: /user_id/omit/make/shuffle */}
          <Listing
            apiPath="garage"
            apiParams={`${entry.entry.user_id}/${entry.entry.internal_id}/all/false`}
            type="row"
            limit={3}
            noUserBadge
            noTypeBadge
            onTotalChange={handleTotal}
          />
          
        </PSPageSegment>

        {entry.entry.make ? (
          <div>

            <PSPageSegment 
              heading={`Other ${entry.entry.make} cars`} 
              all={`/listings/make/${entry.entry.make}`}>

              {/* params: /user_id/omit/make/shuffle */}
              <Listing
                apiPath="garage"
                apiParams={`all/${entry.entry.internal_id}/${entry.entry.make}/false`}
                type="card"
                onTotalChange={handleTotal}
                mobileCarousel
              />

            </PSPageSegment>
            
            <PSPageSegment
              heading={`${entry.entry.make} Parts for sale by ${entry.user.username}`}>

              {/* params: /user_id/omit/make/shuffle */}
              <Listing
                apiPath="listings"
                apiParams={`${entry.entry.user_id}/none/${entry.entry.make}/false`}
                type="card"
                limit={3}
                onTotalChange={handleTotal}
                mobileCarousel
              />

            </PSPageSegment>
            
            <PSPageSegment 
              heading={`All ${entry.entry.make} Parts for sale`} 
              all={`/listings/make/${entry.entry.make}`}>

              {/* params: /user_id/omit/make/shuffle */}
              <Listing
                apiPath="listings"
                apiParams={`all/none/${entry.entry.make}/true`}
                type="card"
                limit={3}
                onTotalChange={handleTotal}
                mobileCarousel
              />

            </PSPageSegment>

            <PSPageSegment 
              heading={`Want-Ads for ${ entry.entry.make }`}
              all={`/wants/make/${entry.entry.make}`}>

              {/* params: /user_id/omit/make/shuffle */}
              <Listing
                apiPath="wants"
                apiParams={`all/none/${entry.entry.make}/true`}
                type="card"
                limit={3}
                onTotalChange={handleTotal}
                mobileCarousel
              />

            </PSPageSegment>

            <PSPageSegment 
              heading="Recent Public Records"
              all={`/car/${entry.entry.internal_id}/records`}>
              
              {/* params: /car_id */}
              <Listing
                apiPath="garage/records"
                apiParams={entry.entry.internal_id}
                type="card"
                limit={6}
                onTotalChange={handleTotal}
              />

            </PSPageSegment>

          </div>
        ) : null }
      </div>
    </PSPage>
  </>

  );
};

export default UserCar;

import React from "react";
import { NavLink } from "react-router-dom";
import logo from "assets/images/logo.svg";
import { useAuth } from 'providers/AuthContext';
import { Button } from 'components/globals/ui/input/Button';
import UserHeader from 'components/globals/ui/UserHeader';
import MobileNavigation from 'components/globals/nav/MobileNavigation'

const Header = () => {

  const { userInfo, isFetching } = useAuth();

  return (
    <header className="block md:hidden fixed w-full z-[10]">
      <div className="bg-black/90 text-white">

				<div className="flex items-center justify-between p-3">
					<NavLink className="nav-item" to="/">
						<img src={logo} alt='Partstash' className="block h-[40px] w-auto" />
					</NavLink>

          <NavLink className="ml-3" to="/posts">
            <p className="text-[14px] font-bold uppercase">Posts</p>
          </NavLink>

					<div className="flex items-center">
            { !isFetching ? (
              <>

                {userInfo ? null : (
                  <NavLink className="button" to="/login">
                    <Button variant='outline'>Login</Button>
                  </NavLink>
                )}

                <span >
                  {userInfo ? (
                    <UserHeader />
                  ) : null }
                </span>

              </>
            ) : null }

            <div className="ml-3">
              <MobileNavigation />
            </div>

          </div>

          
          
				</div>
        
      </div>
    </header>
  );
};

export default Header;

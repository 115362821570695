import React from "react";
import PSPage from "components/globals/ui/layout/Page"
import Listing from "components/globals/Listing"

export default function Home() {

  return (
    <>

      <PSPage>



				<div className="w-[97%] mx-auto md:w-full">
					<h2>Recent Activity</h2>
				</div>

        <Listing
          apiPath="users/global-timeline"
          apiParams=""
          type="grid"
        />
        
      </PSPage>
    </>
  );
};

import { React, memo } from "react";
import { Link } from "react-router-dom";
import Single from "components/globals/image/Single";
import User from "components/globals/user/badge";
import CarBadge from "components/globals/car/badge";
import PSRow from "components/globals/ui/Row";
import PSRowImageContainer from "components/globals/ui/row/ImageContainer"
import PSRowInfo from "components/globals/ui/row/Info"
import PSRowPrice from "components/globals/ui/row/Price"
import PSRowTitle from "components/globals/ui/row/Title"
import PSRowDate from "components/globals/ui/row/Date"
import CarDetails from "components/globals/car/Details"
import TypeBadge from "components/globals/ui/TypeBadge";
import IsYoursBadge from 'components/globals/IsYoursBadge';
import { useSelector, shallowEqual } from 'react-redux';
import EntryUserActions from "components/globals/ui/card/EntryUserActions"

function GenericRow({
	item,
	isFirst,
	noUserBadge,
	noTypeBadge,
	noUserName,
	noYoursBadge = false,
	price,
	carInfo,
	profile,
	path,
	status,
}) {

	const isLoggedIn = useSelector(state => state.auth.isLoggedIn, shallowEqual);

	return (
		<>
			<div className="
				flex
        relative
        items-center
        text-white
        py-2
        my-2" 
				key={item.internal_id}
			>
				
				<div className={isFirst ? (
						null
					) : (
						<div className='absolute -translate-x-[50%] left-[50%] -top-[4px] w-[98%] mx-auto h-[1px] bg-[#101010]/90'></div>
					)}>
					
					<Link className="flex items-start w-full overflow-hidden" to={`/${path}/${item.internal_id}`}>
						<PSRowImageContainer>

							{profile ? (
								<Single image={profile} />
							) : (
								<>
									{item.gallery && item.gallery[0] ? (
										<Single image={item.gallery[0].filename} />
									) : (
										<Single />
									)}
								</>
							)}

							{price && (
								<PSRowPrice price={item.price} />
							)}

						</PSRowImageContainer>

						<PSRowInfo>
							{status ? (
								<PSRowTitle title={item.status} />
							) : (
								<PSRowTitle title={item.title} />
							)}
							
							{ !noYoursBadge && (
								<IsYoursBadge comparison={item.user_id} />
							)}
							<PSRowDate date={item.created_at} />

							{carInfo && (
								<CarDetails 
									year={item.year} 
									make={item.make} 
									model={item.model} 
									trim={item.trim}
								/>
							)}

						</PSRowInfo>
					</Link>

					{!noTypeBadge && (
						<div className="inline-block mb-2">
							<TypeBadge type={item.entry_type} />
						</div>
					)}
					
					{!noUserBadge && (
						<div className="relative text-right">
							<User userId={item.user_id} noUserName={noUserName} />
						</div>
					)}

					{item.car_id && item.car_id.length !== '' && (
						<CarBadge car_id={item.car_id} />
					)}

					{ isLoggedIn && (
						<EntryUserActions comparison={item.user_id} item={item} />
					)}

				</div>
			</div>
		</>
  );
}

export default memo(GenericRow);

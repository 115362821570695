import React from "react";

export default function PSCardInfo({children}) {
  return (
    <>
      <div className="my-2">
				{children}
      </div>
    </>
  );
};

import { React } from "react";

export default function TypeBadge({type}) {

	const getBgColor = (type) => {
		if(!type) { return }
    switch (type.toLowerCase()) {
      case "listing":
        return "bg-green";
      case "want":
        return "bg-red";
      case "garage":
        return "bg-black";
      case "event":
        return "bg-blue";
			case "group":
				return "bg-white text-black";
			case "record":
				return "bg-pink text-black";
			case "timeline":
				return "bg-teal text-white";
      default:
        return "bg-gray";
    }
  };

	const getTypeString = (type) => {
		if(!type) { return }
    switch (type.toLowerCase()) {
      case "listing":
        return "Listing";
      case "want":
        return "Want-Ad";
      case "garage":
        return "User Car";
      case "event":
        return "Event";
			case "group":
				return "Group";
			case "record":
				return "Car Record";
			case "timeline":
				return "Timeline";
      default:
        return "TEST";
    }
  };

	return (
		<>
			{ type ? (
				<div 
					className={`
						py-[3px]
						px-[7px] 
						border-[1px]
						border-black/50
						uppercase
						font-bold
						text-[11px]
						rounded-small
						${getBgColor(type)}`}
					>
					{ getTypeString(type) }
				</div>
			) : null }
			
		</>
  );
}
import React from "react";
// import useEmblaCarousel from 'embla-carousel-react'

export default function PSRows({ children, mobileCarousel = false }) {

  // const [emblaRef] = useEmblaCarousel()

  return (
    <>
      <div className="relative px-[15px]">{children}</div>
      {/* <div className="block md:hidden">
        {mobileCarousel ? (

          <div className="embla" ref={emblaRef}>
            <div className="embla__container pl-[15px]">
              {React.Children.map(children, (child, index) => (
                <div className="embla__slide px-[15px]" key={index}>{child}</div>
              ))}
            </div>
          </div>
        
      ) : (
          <div className="relative px-[15px]">{children}</div>
        )}
      </div>

      <div className="hidden md:block px-[30px] md:px-0">
        {children}
      </div> */}
    </>
  );
}
import React from "react";

export default function BottomLeftSlot1({children}) {
  return (
    <>
      <div className="absolute bottom-[5px] left-[5px]">
				{children}
      </div>
    </>
  );
};

import React from 'react';
import { Button } from 'components/globals/ui/input/Button';
import Spinner from 'components/globals/ui/Spinner';

export default function PSSubmit({loading, label, disabled}) {

  return (
    <>
			<div className="relative py-3">
				<Button disabled={disabled} type="submit">{loading ? <Spinner /> : label}</Button>
			</div>
    </>
  );
}

import * as React from "react"
import { NavLink } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import { cn } from "helpers/utils"

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "components/globals/ui/navigation/nav"

export default function MainNavigation() {
  
  return (
		<NavigationMenu>
			<NavigationMenuList>
				<NavigationMenuItem>
					<NavigationMenuTrigger>Marketplace</NavigationMenuTrigger>
					<NavigationMenuContent>
						<ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
							<li className="row-span-3">
								<NavigationMenuLink asChild>
									<NavLink to='/all'
										className="flex h-full w-full select-none flex-col justify-end rounded-md bg-primary p-6 no-underline outline-none focus:shadow-md"
									>
										<img src={logo} alt='Partstash' className="block h-[30px] w-auto" />
										<div className="mb-2 mt-4 text-lg font-medium">
											All Listings
										</div>
										<p className="text-sm leading-tight text-muted-foreground">
											View all recent listings, want-ads, and more.
										</p>
									</NavLink>
								</NavigationMenuLink>
							</li>
							<ListItem to="/listings" title="Listings">
								Part listings.
							</ListItem>
							<ListItem to="/wants" title="Wants">
								User want-ads.
							</ListItem>
							
						</ul>
					</NavigationMenuContent>
				</NavigationMenuItem>
				<NavigationMenuItem>
					<NavigationMenuTrigger>Social</NavigationMenuTrigger>
					<NavigationMenuContent>
						<ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
							<li className="row-span-3">
								<NavigationMenuLink asChild>
									<NavLink to='/timeline'
										className="flex h-full w-full select-none flex-col justify-end rounded-md bg-primary p-6 no-underline outline-none focus:shadow-md"
									>
										<div className="mb-2 mt-4 text-lg font-medium">
											All User Updates
										</div>
										<p className="text-sm leading-tight text-muted-foreground">
											Recent user updates.
										</p>
									</NavLink>
								</NavigationMenuLink>
							</li>
							
							<ListItem to="/users" title="All Users">
								View all the users.
							</ListItem>

							<ListItem to="/user-cars" title="User Cars">
								View all user cars.
							</ListItem>
							<ListItem to="/groups" title="Groups">
								View all groups.
							</ListItem>
							<ListItem to="/events" title="Events">
								View all events.
							</ListItem>
							
						</ul>
					</NavigationMenuContent>
				</NavigationMenuItem>
				<NavigationMenuItem>
				<NavigationMenuTrigger>Partstash</NavigationMenuTrigger>
					<NavigationMenuContent>
						<ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
							<li className="row-span-3">
								<NavigationMenuLink asChild>
									<NavLink to='/about'
										className="flex h-full w-full select-none flex-col justify-end rounded-md bg-primary p-6 no-underline outline-none focus:shadow-md"
									>
										<div className="mb-2 mt-4 text-lg font-medium">
											About Partstash
										</div>
										<p className="text-sm leading-tight text-muted-foreground">
											Why we are here, and what we're up to.
										</p>
									</NavLink>
								</NavigationMenuLink>
							</li>
							
							<ListItem to="/features" title="Features">
								Unique features and highlights.
							</ListItem>

							<ListItem to="/changelog" title="Changelog">
								Recent changes to the platform.
							</ListItem>
							<ListItem to="/roadmap" title="Roadmap">
								Planned features and updates.
							</ListItem>
						</ul>
					</NavigationMenuContent>
				</NavigationMenuItem>
			</NavigationMenuList>
		</NavigationMenu>
  );
};

const ListItem = React.forwardRef(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <NavLink
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </NavLink>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"

import React from "react";
import { Link } from "react-router-dom";

export default function PSButton({ to, label }) {
	return (
		<>
			<Link to={to} className="
				bg-white
				text-black 
				px-4 
				py-1 
				font-bold 
				text-[13px] 
				rounded-full
				uppercase
				transition
				hover:bg-grey
				hover:text-black">
				<span>{label ? label : "View All"}</span>
			</Link>			
		</>
  );
}
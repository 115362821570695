import React, { createContext, useContext, useState } from 'react';

const DataCacheContext = createContext();

export const DataCacheProvider = ({ children }) => {
  const [dataCache, setDataCache] = useState({});

  const fetchDataWithCache = async (apiRoute) => {

    if (dataCache[apiRoute]) {
      return dataCache[apiRoute];
    } else {
      const response = await fetch(apiRoute);
      const data = await response.json();
      setDataCache((prevCache) => ({ ...prevCache, [apiRoute]: data }));
      return data;
    }
  };
  
  const invalidateCache = (apiRoute) => {
    console.log('trying to invalidate cache for', apiRoute);
    setDataCache((prevCache) => {
      const newCache = { ...prevCache };
      delete newCache[apiRoute];
      return newCache;
    });
  };  

  return (
    <DataCacheContext.Provider value={{ fetchDataWithCache, invalidateCache }}>
      {children}
    </DataCacheContext.Provider>
  );
};

export const useDataCache = () => {
  return useContext(DataCacheContext);
};

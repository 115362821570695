import { React } from "react";
import { FaCar } from "react-icons/fa6";


const CarDetails = ({ year, make, model, trim }) => {


  return (
		<span className="block text-[13px] my-1 text-grey font-bold w-full">
			
			{ make && model ? (
				<span className="flex items-center">
					<span className="relative mr-[3px]">
						<FaCar />
					</span>

					{ year ? (
						<span className="relative ml-1">{ year }</span>
					) : null }

					{ make ? (
						<span className="relative ml-1">{ make }</span>
					) : null }

					{ model ? (
						<span className="relative ml-1">{ model }</span>
					) : null }

					{ trim && trim !== "null" ? (
						<span className="relative ml-1">{ trim }</span>
					) : null }
				</span>
			) : (
				<span className="flex items-center">
					<span className="relative">
						<FaCar />
					</span>

					<span className="relative ml-2">Multiple or Generic</span>
				</span>
			)}

			
		
		</span>
				
			
  );
};

export default CarDetails;


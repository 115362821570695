import React from "react";

export default function PSPage({children}) {
  return (
    <>
      <div className="w-full md:w-11/12 max-w-[1200px] mx-auto relative">
        {children}
      </div>
    </>
  );
};

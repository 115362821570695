import * as React from "react"

const PSSidebar = React.forwardRef(({ className, inset, children }, ref) => (
  <div className="w-[200px] mr-12 rounded-small shrink-0 sticky top-[70px] bg-white/5 border-2 border-black">
    {children}
  </div>
))
 
export {
  PSSidebar,
}
import React from "react";
import PSButton from "components/globals/ui/Button";

export default function PSWidgetHeader({ link, heading }) {
	return (
		<>
			<div className="flex items-center">
				<h3 className="grow">{heading}</h3>
				{ link ? (
					<PSButton to={link} />
				) : null }
			</div>

		</>
    
  );
}
export function getEntryTypePath(entryType) {
	const typeMap = {
		listing: "post",
		garage: "garage/car",
		project: "projects",
		group: "group",
    record: "post",
    event: "events",
    post: "post",
    want: "post",
    update: "post",
		note: "post",
		spot: "post",
		document:  "post",
	};
	
	return typeMap[entryType] || "";
};

export const projectTypes = [
	{
		key: "general",
		label: "General"
	},
	{
		key: "restoration",
		label: "Restoration"
	},
	{
		key: "maintenance",
		label: "Maintenance"
	},
	{
		key: "mod",
		label: "Mod"
	},
	{
		key: "other",
		label: "Other"
	}
]


export const carTypes = [
	{
		key: "daily",
		label: "Daily Driver"
	},
	{
		key: "weekend",
		label: "Weekend Warrior"
	},
	{
		key: "project",
		label: "Project Car"
	},
	{
		key: "garage-queen",
		label: "Garage Queen"
	},
	{
		key: "part-out",
		label: "Part Out"
	},
	{
		key: "other",
		label: "Other"
	}
]

export const postTypes = [
	{
		key: "update",
		label: "Update"
	},
	{
		key: "listing",
		label: "Listing (for sale)"
	},
	{
		key: "want",
		label: "Want-ad"
	},
	{
		key: "note",
		label: "Note"
	},
	{
		key: "spot",
		label: "Spotted"
	},
	{
		key: "record",
		label: "Car Record"
	},
	{
		key: "document",
		label: "Document"
	},
]


export const postCategories = [
	{
		type: "update",
		items: [
			{
				key: "detail",
				label: "Detailing"
			},
			{
				key: "drive",
				label: "Driving"
			},
			{
				key: "general",
				label: "General"
			}
		]
	},
	{
		type: "listing",
		items: [
			{
				key: "listing1",
				label: "Listing Category 1"
			},
			{
				key: "listing2",
				label: "Listing Category 2"
			}
		]
	},
	{
		type: "want",
		items: [
			{
				key: "want1",
				label: "Want Category 1"
			},
			{
				key: "want2",
				label: "Want Category 2"
			}
		]
	},

	{
		type: "note",
		items: [
			{
				key: "note1",
				label: "Note Category 1"
			},
			{
				key: "note2",
				label: "Note Category 2"
			},
		]
	},

	{
		type: "spot",
		items: [
			{
				key: "c&c",
				label: "Cars & Coffee"
			},
			{
				key: "museum",
				label: "Museum"
			},
			{
				key: "shop",
				label: "Shop"
			},
			{
				key: "other",
				label: "Other"
			},
		]
	},

	{
		type: "record",
		items: [
			{
				key: "general",
				label: "General"
			},
			{
				key: "mod",
				label: "Mod"
			},
			{
				key: "restoration",
				label: "Restoration"
			},
			{
				key: "maintenance",
				label: "Maintenance"
			},
			{
				key: "other",
				label: "Other"
			}
		]
	},

	{
		type: "document",
		items: [
			{
				key: "document1",
				label: "Document Category 1"
			},
			{
				key: "document2",
				label: "Document Category 2"
			}
		]
	}
]
import React from "react";
import { useParams } from "react-router-dom";
import Listing from "components/globals/Listing"
import PSPage from "components/globals/ui/layout/Page"

export default function ListingsPage() {
	const { make } = useParams();

  return (
    <>
			<PSPage>

				{make ? (
					<div>
						<h1>Want-Ads for: { make }</h1>
						 {/* params: /user_id/omit/make/shuffle */}
						 <Listing
								apiPath="wants"
								apiParams={`all/none/${make}/false`}
								type="card"
							/>

					</div>
				) : (
					<div>No make supplied</div>
				)}

			</PSPage>

    </>
  );
};

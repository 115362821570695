import * as React from "react";
import { NavLink } from "react-router-dom";

const PSNewLinks = () => (
  <ul>
    <li>
      <NavLink to="/new/car">Garage Car</NavLink>
    </li>
    <li>
      <NavLink to="/new/project">Project</NavLink>
    </li>
    <li>
      <NavLink to="/new/record">Record</NavLink>
    </li>
    <li>
      <NavLink to="/new/listing">Listing</NavLink>
    </li>
    <li>
      <NavLink to="/new/want">Want</NavLink>
    </li>
    <li>
      <NavLink to="/new/group">Group</NavLink>
    </li>
    <li>
      <NavLink to="/new/event">Event</NavLink>
    </li>
    <li>
      <NavLink to="/new/status">Status</NavLink>
    </li>
  </ul>
);

export { PSNewLinks };

import { React, useEffect, useState} from "react";
import { formatDate } from "helpers/utils";


export default function PSCardDate({date, label}) {
	const [formattedDate, setFormattedDate] = useState(0);

	useEffect(() => {
    setFormattedDate(formatDate(date));
  }, [date]);


	return (
		<>
			<span className="block text-[13px] mt-1">
				{label ? (
					<span className="inline-block mr-1 font-bold">{ label }:</span>
				) : null }
				<span className="inline-block">{ formattedDate }</span>
			</span>
		</>
  );
}

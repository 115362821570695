import { React, useMemo } from "react";
import NoResults from "components/globals/NoResults";
import PSGrid from "components/globals/ui/Grid"
import PSRows from "components/globals/ui/Rows"
import Card from "components/globals/ui/Card";
import Row from "components/globals/ui/Row";
import { useFetchData } from "helpers/utils";

export default function Listing({
	apiPath,
	apiParams,
  displayType,
	type, 
	noUserBadge, 
  noUserName,
  noTypeBadge,
  noYoursBadge,
	mobileCarousel
}) {

  const url = useMemo(() => {
    return `${process.env.REACT_APP_SERVER_URI}/api/${apiPath}/${apiParams}`;    
  }, [apiPath, apiParams]);

  const { data: entries, loading, error } = useFetchData(
		url,
		[],
	);

  // Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;
  

  return (
    <>
      <div>
        {displayType === 'row' ? (
          <PSRows mobileCarousel={mobileCarousel}>
            {entries.entries && entries.entries.length > 0 ? (
              <div>
                {entries.entries.map((entry) => (
                  <Row
                    key={entry._id}
                    item={entry}
                    path={type}
                    carInfo
                    noYoursBadge={noYoursBadge}
                    noTypeBadge={noTypeBadge}
                    noUserBadge={noUserBadge}
                    noUserName={noUserName}
                  />
                ))}
              </div>
            ) : (
              <NoResults label="No items Found" />
            )}
          </PSRows>
        ) : (
          <div>
            {entries.entries && entries.entries.length > 0 ? (
              <PSGrid mobileCarousel={mobileCarousel}>
                {entries.entries.map((entry) => (
                  <Card
                    key={entry._id}
                    item={entry}
                    path={type}
                    carInfo
                    noUserBadge={noUserBadge}
                    noUserName={noUserName}
                    noTypeBadge={noTypeBadge}
                    noYoursBadge={noYoursBadge}
                  />
                ))}
              </PSGrid>
            ) : (
              <NoResults label="No items Found" />
            )}
          </div>
        )}
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import fallbackImage from "assets/images/default.jpg";
import baseImage from "assets/images/base-template.jpg";

/*

sizes: 

default: 600x500
medium: 400x300
small: 100x100
profile: 27x27

*/

const Single = ({ image, className, bucket, natural = false }) => {

	const [img, setImg] = useState(fallbackImage);

  useEffect(() => {
    setImg(image);
  }, [image]); 

  return (
    <div className={className}>
			{ img ? (
				<>
					{ natural ? (
						<img src={`//ps-images.imgix.net/${img}?q=70&width=800`} alt='baseImage' />
					) : (
						<div 
							className="bg-cover bg-center absolute w-full h-full t-0 l-0"
							style={{ 
								backgroundImage: `url(//ps-images.imgix.net/${img}?q=70&width=800)`
							}}
						> 
							<img src={baseImage} alt='baseImage' className="opacity-0 block" />
						</div>
					)}
				</>
			) : (
				<>
					<div 
						style={{ backgroundImage: `url(${fallbackImage})` }} 
						className="absolute inset-0 bg-cover scale-[1.5]">	
							<img src={baseImage} alt='baseImage' className="opacity-0 block" />
					</div>
				</>
			)}
			
    </div>
  );
};

export default Single;
import React from "react";
import Listing from "components/globals/Listing"
import PSPage from "components/globals/ui/layout/Page"

export default function ListingsPage() {
  return (
    <>
      <PSPage>
        
        <h1>Posts</h1>

        {/* params: :index/:omit/:shuffle/:limit */}
        <Listing
          apiPath="post"
          apiParams="0/0/0/0"
          displayType="card"
          type="post" />

      </PSPage>
    </>
  );
};

import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PSPage from "components/globals/ui/layout/Page"
import { formatDate, useFetchAuthedData } from "helpers/utils";
import { useGetUserEntryQuery } from 'services/auth/authService';
import parse from "html-react-parser";
import IsYoursBanner from 'components/globals/IsYoursBanner'

const ProjectPage = () => {
  const [formattedDate, setFormattedDate] = useState(0);
  const { id } = useParams();
	const { data } = useGetUserEntryQuery(`projects/${id}`);

  // double entry, entry.entry & entry.user
	const { data: entry, loading, error } = useFetchAuthedData(
		`projects/${id}`,
		[id],
	);

  useEffect(() => {
    setFormattedDate(formatDate(entry?.entry?.created_at));
  }, [entry]);

  // Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <PSPage>

        <IsYoursBanner label="Your Project" entry={entry.entry} />

        <h1>Project: { entry.entry.title }</h1>
        <p>On: {formattedDate}</p>

        <div className="mt-3">{parse(entry.entry.body)}</div>

        <ul>
          { entry.entry.car_id && (
            <li>Car: {entry.entry.car_id}</li>
          )}
          
          <li>LINK: All Projects</li>
          <li>LINK: User Dashboard</li>
        </ul>


      </PSPage>
    </>
    
  );
};

export default ProjectPage;

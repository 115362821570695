import React from 'react';
import PSPage from 'components/globals/ui/layout/Page';
import { useAuth } from 'providers/AuthContext';
import {DashboardTemplate } from './Dashboard'

export default function Settings() {
  const { userInfo, isFetching } = useAuth();

  return (
    <PSPage>
      { !isFetching && userInfo ? (
				<DashboardTemplate userInfo={userInfo} title="Settings & Preferences">
					<p>Settings Here</p>
				</DashboardTemplate>
      ) : null }
    </PSPage>
  );
}
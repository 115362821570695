// import logo from "./logo.svg";
import "./App.css";

import { React } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DataCacheProvider } from './cache/DataCacheContext';
import { AuthProvider } from './providers/AuthContext';
import { Provider } from 'react-redux'
import store from './store/store'

// home
import Home from "./pages/Home";
import Welcome from "./pages/Welcome";
import ProtectedRoute from './routing/ProtectedRoute'

// app pages
import Cars from "./pages/cars/Index";
import Car from "./pages/cars/Detail";
import CarCreate from "./pages/cars/Create";
import CarRecords from "./pages/cars/Records";
import DesktopHeader from "./components/globals/layout/header/Desktop";
import MobileHeader from "./components/globals/layout/header/Mobile";
import Footer from "./components/globals/layout/footer/Footer";
import Users from "./pages/users/Index";
import User from "./pages/users/Detail";
import UserGaragePage from "./pages/users/Garage";
import UserTimelinePage from "./pages/users/Timeline";
import Listings from "./pages/listings/Index";
import Listing from "./pages/listings/Detail";
import ListingsByUser from "./pages/listings/User";
import ListingsByMake from "./pages/listings/Make";
import Wants from "./pages/wants/Index";
import Want from "./pages/wants/Detail";
import WantsByUser from "./pages/wants/User";
import WantsByMake from "./pages/wants/Make";
import Groups from "./pages/groups/Index";
import Group from "./pages/groups/Detail";
import Events from "./pages/events/Index";
import Event from "./pages/events/Detail";
import Project from "./pages/projects/Detail";

import Posts from "./pages/posts/Index";

// entry create
import ProjectCreate from "./pages/projects/Create";
import GroupCreate from "./pages/groups/Create";
import EventCreate from "./pages/events/Create";

// Post
import PostCreate from "./pages/posts/Create";

// marketing 
import About from "./pages/marketing/About";
import Features from "./pages/marketing/Features";
import Changelog from "./pages/marketing/Changelog";
import Roadmap from "./pages/marketing/Roadmap";

// auth
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Dashboard from "./pages/dashboard/Index";
import Settings from "./pages/dashboard/Settings";


function App() {

  return (
    <div className="App">

      <Provider store={store}>
        <AuthProvider>
          <DataCacheProvider>
            <BrowserRouter>
              <DesktopHeader />
              <MobileHeader />
              <div className="relative pt-[65px] main-page">
                <Routes>

                  {/* home */}
                  <Route path="/" element={<Welcome />} />
                  <Route path="/home" element={<Home />} />

                  {/* app routes */}
                  <Route path="/user-cars" element={<Cars />} />
                  <Route path="/user-cars/:make" element={<Cars />} />
                  <Route path="/user-cars/:make/:model" element={<Cars />} />
                  <Route path="/user-cars/:make/:model" element={<Cars />} />
                  <Route path="/car/:id" element={<Car />} />
                  <Route path="/car/:id/records" element={<CarRecords />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/users/:username" element={<User />} />
                  <Route path="/users/:username/timeline" element={<UserTimelinePage />} />
                  <Route path="/users/:username/garage" element={<UserGaragePage />} />

                  <Route path="/groups" element={<Groups />} />
                  <Route path="/groups/:id" element={<Group />} />
                  <Route path="/events" element={<Events />} />
                  <Route path="/events/:id" element={<Event />} />
                  
                  <Route path="/listings" element={<Listings />} />
                  <Route path="/listings/user/:username" element={<ListingsByUser />} />
                  <Route path="/listings/make/:make" element={<ListingsByMake />} />
                  <Route path="/listings/:id" element={<Listing />} />

                  <Route path="/wants" element={<Wants />} />
                  <Route path="/wants/user/:username" element={<WantsByUser />} />
                  <Route path="/wants/make/:make" element={<WantsByMake />} />
                  <Route path="/wants/:id" element={<Want />} />

                  <Route path="/projects/:id" element={<Project />} />

                  <Route path="/posts" element={<Posts />} />

                  {/* auth routes routes */}
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />

                  <Route element={<ProtectedRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                  </Route>

                  <Route element={<ProtectedRoute />}>
                    <Route path="/settings" element={<Settings />} />
                  </Route>
                  
                  <Route element={<ProtectedRoute />}>

                    {/* Create & edit routes */}
                    <Route path="/new/post" element={<PostCreate />} />
                    <Route path="/edit/post/:id" element={<PostCreate edit />} />

                    <Route path="/new/group" element={<GroupCreate />} />
                    <Route path="/edit/group/:id" element={<GroupCreate edit />} />

                    <Route path="/new/event" element={<EventCreate />} />
                    <Route path="/edit/event/:id" element={<EventCreate edit />} />

                    <Route path="/new/car" element={<CarCreate />} />
                    <Route path="/edit/car/:id" element={<CarCreate edit />} />

                    <Route path="/new/project" element={<ProjectCreate />} />
                    <Route path="/edit/project/:id" element={<ProjectCreate edit />} />
                    
                  </Route>


                  {/* marketing routes */}
                  <Route path="/about" element={<About />} />
                  <Route path="/features" element={<Features />} />
                  <Route path="/roadmap" element={<Roadmap />} />
                  <Route path="/changelog" element={<Changelog />} />


                </Routes>
              </div>
              <Footer />
            </BrowserRouter>
          </DataCacheProvider>
        </AuthProvider>
      </Provider>
    </div>
  );
}

export default App;

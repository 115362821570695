import { React } from "react";
import fallbackImage from "assets/images/default.jpg";
import baseImage from "assets/images/base-template.jpg";
import { DotButton, useDotButton } from 'components/globals/ui/carousel/EmblaCarouselDotButton'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from 'components/globals/ui/carousel/EmblaCarouselArrowButtons'
import useEmblaCarousel from 'embla-carousel-react'

const Gallery = ({ gallery, bucket }) => {

  const [emblaRef, emblaApi] = useEmblaCarousel({
		loop: true
	})

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)


	return (
		<div className="relative">
			{gallery.length > 0 ? (
				
				<div>
					{gallery.length > 1 ? (

						<>

							<div className="embla w-full max-w-full rounded-default" ref={emblaRef}>
								<div className="embla__container">
									{gallery.map((image, index) => (
											<div
												style={{ 
													backgroundImage: `url(//ps-images.imgix.net/${image.filename}?q=70&width=800)`
												}}
												className="embla__slide  bg-cover bg-center" key={index}>	
												<img src={baseImage} alt='baseImage' className="opacity-0 w-full h-auto block" />
											</div>
										))
									}
								</div>
							</div>


						
							<div className="embla__controls">
								<div className="embla__buttons">
									<PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
									<NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
								</div>

								<div className="embla__dots">
									{scrollSnaps.map((_, index) => (
										<DotButton
											key={index}
											onClick={() => onDotButtonClick(index)}
											className={'embla__dot'.concat(
												index === selectedIndex ? ' embla__dot--selected' : ''
											)}
										/>
									))}
								</div>
							</div>

						</>

						) : (
							<div 
								style={{ 
									backgroundImage: `url(//ps-images.imgix.net/${gallery[0].filename}?q=70&width=800)`
								}}
								className="rounded-small my-1 bg-cover bg-center w-full">	
								<img src={baseImage} alt='baseImage' className="opacity-0 block w-full h-auto" />
							</div>
						)}
					</div>
			) : (
				<div 
					style={{ backgroundImage: `url(${fallbackImage})` }} 
					className="rounded-small my-1 bg-cover bg-center w-[300px] h-[200px]">	
					<img src={baseImage} alt='baseImage' className="opacity-0 block" />
				</div>
			)}
		</div>
	);
};

export default Gallery;

import React from "react";
import TypeBadge from "components/globals/ui/TypeBadge";

export default function PSCardImageContainer({children, type}) {
  return (
    <>
      <div className="relative border-2 border-[#0A0A0A] rounded-default overflow-hidden aspect-[4/2.5] md:aspect-square">
        <div className="absolute
          top-2
          left-2 
          z-[2]">
          <TypeBadge type={type} />
        </div>
				{children}
      </div>
    </>
  );
};

import React from "react";
import PSWidgetContainer from "components/globals/ui/widget/Container"
import PSWidgetScroller from "components/globals/ui/widget/Scroller"
import PSWidgetHeader from "components/globals/ui/widget/Header"
import PSGrid from "components/globals/ui/Grid"
import PSPage from "components/globals/ui/layout/Page"
import Listing from "components/globals/Listing"

export default function Home() {

  return (
    <>

      <PSPage>
        <PSGrid>
          <PSWidgetContainer>
            <PSWidgetHeader 
              heading="Recent Users" 
              link="/users"
            />
            <PSWidgetScroller>
              <Listing
                apiPath="garage"
                apiParams={`all/none/all/false`}
                type="row"
                limit={3}
                noUserName
                noTypeBadge
              />
            </PSWidgetScroller>
          </PSWidgetContainer>
          
        
          
          <PSWidgetContainer>
            <PSWidgetHeader 
              heading="Recent Users" 
              link="/users" />
            <PSWidgetScroller>

              <Listing
                apiPath="users"
                apiParams=""
                type="row"
                limit={3}
                noTypeBadge
              />

            </PSWidgetScroller>
          </PSWidgetContainer>

          {/* params: /user_id/omit/make */}
          <PSWidgetContainer>
            <PSWidgetHeader 
              heading="Recent Listings" 
              link="/listings" />
            <PSWidgetScroller>

              <Listing
                apiPath="listings"
                apiParams={`all/none/all/false`}
                type="row"
                limit={3}
                noUserName
                noTypeBadge
              />

            </PSWidgetScroller>
          </PSWidgetContainer>

          {/* params: /user_id/omit/make */}
          <PSWidgetContainer>
            <PSWidgetHeader 
              heading="Recent Want-Ads" 
              link="/wants" />
            <PSWidgetScroller>

              <Listing
                apiPath="wants"
                apiParams={`all/none/all/false`}
                type="row"
                limit={3}
                noUserName
                noTypeBadge
              />

            </PSWidgetScroller>
          </PSWidgetContainer>

          <PSWidgetContainer>
            <PSWidgetHeader 
              heading="Recent Events" 
              link="/events" />
            <PSWidgetScroller>

              <Listing
                apiPath="events"
                apiParams=""
                type="row"
                limit={3}
                noUserName
                noTypeBadge
              />

            </PSWidgetScroller>
          </PSWidgetContainer>

          <PSWidgetContainer>
            <PSWidgetHeader 
              heading="Recent Groups" 
              link="/groups" />
            <PSWidgetScroller>

              <Listing
                apiPath="groups"
                apiParams=""
                type="row"
                limit={3}
                noUserName
                noTypeBadge
              />

            </PSWidgetScroller>
          </PSWidgetContainer>

        </PSGrid>


      <div className="w-[97%] mx-auto md:w-full">
        <h2>All Activity</h2>
        <h3>TODO: Add filter?</h3>
      </div>



        {/* params: none yet */}
        <Listing
          apiPath="users/global-timeline"
          apiParams=""
          type="grid"
        />
        
      </PSPage>
    </>
  );
};

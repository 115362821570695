import React, { useState, useEffect } from 'react';

const SingleImageUploader = ({ register, reset, existingImage, onImageChange }) => {
  const [previewImage, setPreviewImage] = useState(existingImage || null);

  useEffect(() => {
    if (existingImage) {
      setPreviewImage(existingImage);
    }
  }, [existingImage]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImage = reader.result;
        setPreviewImage(newImage);
        if (onImageChange) onImageChange(newImage);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
      reset({ file: '' });
      if (onImageChange) onImageChange(null);
    }
  };

  const clearPreview = () => {
    setPreviewImage(null);
    reset({ file: '' });
    if (onImageChange) onImageChange(null);
  };

  return (
    <div className="my-5">
      <input type="file" {...register('file')} onChange={handleFileChange} />
      {previewImage && (
        <div className="mt-3">
          <img
            src={previewImage}
            alt="Preview"
            style={{ maxWidth: '100%', maxHeight: '200px' }}
          />
          <button
            type="button"
            className="ml-2 btn btn-sm btn-danger"
            onClick={clearPreview}
          >
            Clear
          </button>
        </div>
      )}
    </div>
  );
};

export default SingleImageUploader;

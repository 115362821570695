import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Gallery from "components/globals/image/Gallery";
import User from "components/globals/user/badge";
import Listing from "components/globals/Listing";
import parse from "html-react-parser";
import PSPage from "components/globals/ui/layout/Page"
import PSPageSegment from "components/globals/ui/layout/PageSegment"
import { formatDate, useFetchData } from "helpers/utils";

const ListingView = () => {
  const [formattedDate, setFormattedDate] = useState(0);
  const { id } = useParams();

	// double entry, entry.entry & entry.user
	const { data: entry, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/wants/single/${id}`,
		[id],
	);

	useEffect(() => {
    setFormattedDate(formatDate(entry?.entry?.created_at));
  }, [entry]);

	const handleTotal = (total) => {
    // console.log('total', total);
  };

	// Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

  return (
    <PSPage>

			<div>
				{entry.entry.gallery.length > 0 ? (
					<Gallery gallery={entry.entry.gallery} bucket='listings' /> 
				) : (
					<div>FALLBACK IMG</div>
				)}
			</div>
			
			<h1>Want-Ad: { entry.entry.title }</h1>
			<p>On: {formattedDate}</p>

			{window.location.state ? (
				<div>Back detected</div>
			) : (
				<div>no back detected</div>
			)}

			<div>
				<h2>Details</h2>

				<User userId={entry.entry.user_id} />

				<ul>
					{entry.entry.type ? (
						<li>Type: { entry.entry.type }</li>
					) : null }
					
					{entry.entry.price ? (
						<li>Willing to pay: ${ entry.entry.price }</li>
					) : null }

					{entry.entry.vin ? (
						<li>VIN: { entry.entry.vin }</li>
					) : null }

					{entry.entry.year ? (
						<li>Year: { entry.entry.year }</li>
					) : null }

					{entry.entry.make ? (
						<li>Make: { entry.entry.make }</li>
					) : null }

					{entry.entry.model ? (
						<li>Model: { entry.entry.model }</li>
					) : null }
					
					{entry.entry.trim ? (
						<li>Trim: { entry.entry.trim }</li>
					) : null }

					{entry.entry.partNumber ? (
						<li>Part Number: { entry.entry.partNumber }</li>
					) : null }

				</ul>
				
				{entry.entry.body ? (
					<div>{parse(entry.entry.body)}</div>
				) : null }

				<PSPageSegment 
					heading={`Other want-ads from ${ entry.user.username }`} 
					all={`/wants/user/${entry.user.username}`}>

					{/* params: /user_id/omit/make/shuffle */}
					<Listing
						apiPath="wants"
						apiParams={`${entry.entry.user_id}/${entry.entry.internal_id}/all/false`}
						type="card"
						limit={3}
						onTotalChange={handleTotal}
						mobileCarousel
					/>

				</PSPageSegment>
				
				{entry.entry.make && (
					<>
						<PSPageSegment 
							heading={`Other want-ads for: ${ entry.entry.make }`} 
							all={`/wants/make/${entry.entry.make}`}>

							{/* params: /user_id/omit/make/shuffle */}
							<Listing
								apiPath="wants"
								apiParams={`all/${entry.entry.internal_id}/${entry.entry.make}/true`}
								type="card"
								limit={3}
								onTotalChange={handleTotal}
								mobileCarousel
							/>

						</PSPageSegment>

						<PSPageSegment 
							heading={`Items for sale for: ${ entry.entry.make }`} 
							all={`/listings/make/${entry.entry.make}`}>

							{/* params: /user_id/omit/make/shuffle */}
							<Listing
								apiPath="listings"
								apiParams={`all/${entry.entry.internal_id}/${entry.entry.make}/true`}
								limit={3} 
								type="card"
								onTotalChange={handleTotal} 
								mobileCarousel
							/>

						</PSPageSegment>

						<PSPageSegment 
							heading={`${ entry.entry.make } User Cars`} 
							all={`/cars/make/${entry.entry.make}`}>

							{/* params: /user_id/omit/make/shuffle */}
							<Listing
								apiPath="garage"
								apiParams={`${entry.entry.internal_id}/${entry.entry.internal_id}/${entry.entry.make}/true`}
								type="card"
								limit={3}
								onTotalChange={handleTotal}
								mobileCarousel
							/>
							
						</PSPageSegment>

					</>
				)}
			</div>
    </PSPage>
  );
};

export default ListingView;

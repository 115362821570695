import React from "react";
import Listing from "components/globals/Listing"
import PSPage from "components/globals/ui/layout/Page"

export default function ListingsPage() {
  return (
    <>
      <PSPage>
        <h1>Listings</h1>
        {/* params: /user_id/omit/make/shuffle */}
        <Listing
          apiPath="listings"
          apiParams={`all/none/all/false`}
          type="card" />
      </PSPage>
    </>
  );
};

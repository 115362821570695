import { React , useState} from "react";
import PSButton from "components/globals/ui/Button";
import PSInputText from "components/globals/ui/input/PSInputText"
import { FaX } from "react-icons/fa6";

export default function CreateProjectNotes({ onNotesChange }) {
	const [notes, setNotes] = useState([]); 

	const handleAddNote = (note) => {
		console.log('in note');
    // setNotes([...notes, note]);
  };

	const handleNoteChange = (index, value) => {
		console.log('in note chang');
    // const updatedNotes = [...notes];
    // updatedNotes[index] = value;
    // setNotes(updatedNotes);
    // if (onNotesChange) {
    //   onNotesChange(updatedNotes);
    // }
  };


	return (
		<div className="mb-3">
			<h3>Project Notes</h3>

			{notes.map((note, index) => (
        <div key={index}>
					<PSInputText
            name={`noteInfo_${index}`}
            label="Note Info"
            type="text"
            onKeyUp={(e) => handleNoteChange(index, e.target.value)}
          />
					<span className="w-[20px] h-[20px] rounded-full bg-red text-white">
						<FaX />
					</span>
				</div>
      ))}
      
			<PSButton onClick={handleAddNote} label="Add Note" />

		</div>
  );
}
import { React } from "react";
import clsx from "clsx";

export default function PSBadge({label, color}) {

	const colorClasses = colorMap[color] || "text-gray-500 bg-gray-500/30"; 

	return (
		<>
			<span
				className={clsx(
					"py-[3px] px-2 rounded-full uppercase font-bold text-[11px] tracking-[.5px]",
					colorClasses
				)}
			>
				{label}
			</span>
		</>

  );
}

const colorMap = {
	green: "text-lightGreen bg-green/60",
	gold: "text-gold bg-gold/30",
};
import { React, useEffect } from "react";
import PSPage from "components/globals/ui/layout/Page"
import { useForm, FormProvider } from 'react-hook-form';
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
// import Error from 'components/globals/Error'
// import { registerUser } from 'store/authSlice'
import { Button } from "components/globals/ui/input/Button"
// import PSInputText from "components/globals/ui/input/PSInputText"
// import PSSubmit from "components/globals/ui/input/PSSubmit"
// import SingleImageUploader from "components/globals/ui/image/SingleImageUploader"
// import { prepareFormData } from "helpers/utils";

export default function Register() {
  const { loading, userInfo, error, success } = useSelector(
    (state) => state.auth
  )
  // const dispatch = useDispatch()
  // const form = useForm();
  // const { handleSubmit, reset, register } = form;
  const navigate = useNavigate()

  useEffect(() => {
    if (success) navigate('/login')
    if (userInfo) navigate('/dashboard')  
  }, [navigate, userInfo, success])


  // const submitForm = async (data) => {

  //   // check if passwords match
  //   if (data.password !== data.confirmPassword) {
  //     alert('Password mismatch');
  //     return;
  //   }
    
  //   const formData = prepareFormData(data); 
  //   dispatch(registerUser(formData))
  // };

  return (
    <>
      <PSPage>
        <div className="max-w-[600px] w-11/12 mx-auto mt-lg">
          <h1>Request an invitation</h1>
          <Button variant='outline'><a href="mailto:matt@partstash.com">Request</a></Button>
        
          {/* <FormProvider {...form}>
            <form onSubmit={handleSubmit(submitForm)}>
              {error && <Error>{error}</Error>}

              <PSInputText register={register} name='username' label='Username' type='text' />
              <PSInputText register={register} name='firstName' label='First Name' type='text' />
              <PSInputText register={register} name='lastName' label='Last Name' type='text' />
              <PSInputText register={register} name='email' label='Email' type='text' />
              <PSInputText register={register} name='password' label='Password' type='password' />
              <PSInputText register={register} name='confirmPassword' label='Confirm Password' type='password' />
              <PSInputText register={register} name='zip' label='Zip Code' type='number' />

              <SingleImageUploader register={register} reset={reset} />

              <PSSubmit loading={loading} label='Create Account' />

              <NavLink to='/login'>
                <Button variant='link'>Login if you already have an account</Button>
              </NavLink>

            </form>
          </FormProvider> */}
          
        </div>
      </PSPage>
    </>
  );
};



import React from 'react';

import {DashboardSidebar } from './Sidebar'

export const DashboardTemplate = function({title, userInfo, children}) {

  return (
    <div className="w-11/12 mx-auto">
			<h1>{title}</h1>
			<div className="md:flex md:items-stretch mt-xs">
				<DashboardSidebar userInfo={userInfo} />
				<div className="grow border-[1px] border-[#303030] rounded-lg">
					{children}
				</div>
			</div>
		</div>
  );
}
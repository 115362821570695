import { React } from "react";


const NoResults = ({ label }) => {
  return (
		<div className="text-red border border-red p-4 my-4">{ label }</div>
  );
};

export default NoResults;
